import M from './multiverse'
import m from 'mithril'
import u from 'umbrellajs'

import api from './api'
import debounce from './debounce'
import imgblat from './imgblat'
import utils from './utils'

function closeMenu(e) {
  if (!e || u(e.target).closest('div.actions').length === 0) {
    u('ul.menu').removeClass('open')
  }
}

export default {
  oninit() {
    this.posts = []
    this.frameList = []
    this.extendedFrameList = []
    this.frames = null
    api.title("My Universe")
    this.load(api.get("posts?visibility=" + (m.route.param("key") || "public"), api.token))
    api.getFrames().
      then(list => {
        this.frames = {}
        this.frameList = list.frames.slice(0, 4)
        this.extendedFrameList = list.frames.slice(4)
        list.frames.map(f => this.frames[f.id] = f.definition)
      }).catch()
    this.autoSave = debounce(e => api.saveProfile(e.target.parentNode), 1000)
  },

  oncreate() {
    u(document).on('mousedown', closeMenu)
  },

  onbeforeremove() {
    u(document).off('mousedown', closeMenu)
  },

  newFile(e) {
    imgblat(e.target.files, {maxWidth: 160, maxHeight: 160}, obj => {
      api.saveProfile({"user:avatar_file": obj.src, "user:nametag": api.profile?.nametag}).then(() => {
        this.avatar_file = api.avatar() + "#" + new Date().getTime()
        m.redraw()
      })
    })
  },

  load(query) {
    query.then(obj => {
      this.pages = obj.pages
      obj.posts.map(p => {
        p.summary = M.postSummary(p, 'updated_at')
        // p.content.children = (p.content.children || []).filter(c => (c.y || 0) < 200)
        this.posts.push(p)
      })
    })
  },

  delete(post) {
    if (confirm(`Are you sure you want to delete '${post.title || post.url}'?`)) {
      api.deletePost(post.id).
        then(() => {
          this.posts = this.posts.filter(p => p.id !== post.id)
          m.redraw()
        })
    }
  },

  nextPage(a) {
    a.parentNode.removeChild(a)
    let url = this.pages.next.substr(4)
    delete this.pages.next
    this.load(api.get(url, api.token))
  },

  dropActions(e) {
    e.stopPropagation()
    closeMenu()
    u(e.target).closest("div.actions").children("ul.menu").toggleClass('open')
  },

  selfClose: fn => e => { u(e.target).closest("ul.menu").removeClass('open'); fn(e) },

  viewFeed() {
    if (!this.pages)
      return []
    if (this.posts.length === 0)
      return <p>This page will show the posts you create. Give it shot on the {m(m.route.Link, {href: "/compose"}, "compose")} page.</p>

    return <div class="feed">
      {this.frames && this.posts.length > 0 && this.posts.map(post => {
        let def = this.frames[post.frame_id]
        let compose = () => m.route.set("/compose/" + post.stub)
        let link = () => m.route.set("/" + post.url)
        let title, box, more
        box = M.post(post.url, def, api.author(), post)
        title = M.title(def, post)
        title.attrs.onclick = link
        return <div key={utils.id(post)} class={`post post-preview`}> 
          <div class="actions">
            <a href="#" onclick={this.dropActions}>
              <svg class="mvt" width="16" height="5" viewBox="0 0 16 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                  <path d="M2.47339 3.73448C1.95809 3.73448 1.55991 3.58809 1.27884 3.29531C0.997769 3.00253 0.857234 2.66291 0.857234 2.27643C0.857234 1.87825 0.991913 1.53863 1.26127 1.25756C1.54234 0.976487 1.94638 0.835952 2.47339 0.835952C3.00039 0.835952 3.39857 0.976487 3.66793 1.25756C3.93729 1.53863 4.07197 1.87825 4.07197 2.27643C4.07197 2.66291 3.93143 3.00253 3.65036 3.29531C3.3693 3.58809 2.97697 3.73448 2.47339 3.73448ZM7.79148 3.73448C7.27618 3.73448 6.878 3.58809 6.59693 3.29531C6.31586 3.00253 6.17533 2.66291 6.17533 2.27643C6.17533 1.87825 6.31001 1.53863 6.57936 1.25756C6.86043 0.976487 7.26447 0.835952 7.79148 0.835952C8.31848 0.835952 8.71667 0.976487 8.98602 1.25756C9.25538 1.53863 9.39006 1.87825 9.39006 2.27643C9.39006 2.66291 9.24953 3.00253 8.96846 3.29531C8.68739 3.58809 8.29506 3.73448 7.79148 3.73448ZM13.1096 3.73448C12.5943 3.73448 12.1961 3.58809 11.915 3.29531C11.634 3.00253 11.4934 2.66291 11.4934 2.27643C11.4934 1.87825 11.6281 1.53863 11.8975 1.25756C12.1785 0.976487 12.5826 0.835952 13.1096 0.835952C13.6366 0.835952 14.0348 0.976487 14.3041 1.25756C14.5735 1.53863 14.7082 1.87825 14.7082 2.27643C14.7082 2.66291 14.5676 3.00253 14.2865 3.29531C14.0055 3.58809 13.6132 3.73448 13.1096 3.73448Z" fill="currentColor" />
                </g>
                <defs>
                  <filter id="filter0_d" x="0.857178" y="0.835938" width="14.8509" height="3.89853" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dx="1" dy="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.713726 0 0 0 0 0.752941 0 0 0 0 1 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </a>
            <ul class="menu mvt_shadow">
              <li><a class="icon" href="#" onclick={compose}>
                <span>Edit post</span>
              </a></li>
              <li><a class="icon" href="#" onclick={this.selfClose(e => utils.copyLink())}>
                <span>Copy link to post</span>
              </a></li>
              <li><a class="icon" href="#" onclick={() => this.delete(post)}>
                <span>Delete post</span>
              </a></li>
            </ul>
          </div>
          {title}
          {box}
        </div>
      })}
      </div>
  },

  mario(frameList) {
    return frameList.map(frame => <div
      onclick={e => m.route.set("/frame/" + frame.id)}>
        {M.box('box', frame.definition.main, 
          M.text('p', frame.definition.main.text, 'Aa', {}))}</div>)
  },

  view() {
    return <div id="home">
      <div id="settings">
        <div class="col2">
          <div class="avatar">
            <a href="#" onclick={() => u("#new-avatar").first().click()}>
            {this.avatar_file ? 
              <img src={this.avatar_file} /> :
              (api.profile?.avatar ?
                <img src={api.avatar()} /> :
                m("svg", {class: "mvt", width: 96, height: 96, viewBox: "0 0 195 194", xmlns: "http://www.w3.org/2000/svg"}, [
                  m("rect", {x: "1.34375", y: "0.612305", width: "192.611", height: "192.611", rx: "1.5"}),
                  m("path", {d: "M94.0534 1.56079C82.6236 33.214 62.2257 97.3997 72.0729 100.917C84.3818 105.313 126.586 90.3657 121.31 110.589C117.09 126.767 101.38 147.224 94.0534 155.431"}),
                  m("rect", {x: "29.9844", y: "48.3931", width: "9.22519", height: "25.5605", rx: "3", fill: "currentColor"}),
                  m("rect", {x: "130.062", y: "48.3931", width: "9.22519", height: "25.5605", rx: "3", fill: "currentColor"})
                ]))}
              <br />
              Click to change
            </a>
            <form id="avatar-form">
              <input type="file" accept="image/*" name="user:avatar_file" id="new-avatar"
                onchange={e => this.newFile(e)}
                style="display:none" />
              <input type="hidden" name="user:hidden" value="empty" />
            </form>
          </div>

          <div class="fields">
            <div class="field">
              <form id="settings-form" onsubmit={e => e.preventDefault()}>
                <input type="text" id="nametag" name="user:nametag" autocorrect="off" autocapitalize="none"
                  oncreate={v => v.dom.value = api.profile?.nametag} oninput={e => this.autoSave(e)} />
              </form>
            </div>

            <div class="field">
              <p class="handle">@{api.profile?.username}</p>
            </div>

            <div class="frames">
              <div class='mario grid-6'>
                {this.mario(this.frameList)}
                {this.extendedFrameList.length > 0 && <div class="extended"
                  onclick={e => u(e.target).closest(".extended").children(".drawer").toggleClass('open')}>
                  {M.box('box', M.defaultFrame.main,
                    M.text('p', M.defaultFrame.main.text, '...', {}))}
                  <div class="drawer">
                    <svg class="tri" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="8.5 0 0 17 17 17" style="fill: var(--mvt-toolback)" />
                    </svg>
                    <div class="mario grid-5 mvt_shadow">
                      {this.mario(this.extendedFrameList)}
                    </div>
                  </div>
                  </div>}
                <div><a class="button" href="#" onclick={() => m.route.set("/frame")}>+ Create</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul class="tabs mvt_bline">
        {M.link("/profile", "Published")}
        {M.link("/profile/posts/unlisted", "Unlisted")}
        {M.link("/profile/posts/private", "Private")}
        {M.link("/profile/posts/draft", "Drafts")}
      </ul>
      {this.viewFeed()}
      {this.pages && this.pages.items > 0 && this.pages.next &&
        <div class="paginate">
          <a href="#" onclick={e => this.nextPage(e.target)}>
            Show More
          </a>
        </div>}
    </div>
  }
}
