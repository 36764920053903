function handlepaste (e, fn) {
  var types, pastedData, savedContent, dt;
  
  // Browsers that support the 'text/html' type in the Clipboard API (Chrome, Firefox 22+)
  if (e.clipboardData?.types && e.clipboardData.getData) {
    dt = e.clipboardData;
  } else if (e.dataTransfer?.getData) {
    dt = e.dataTransfer;
  }
      
  if (dt) {
    // Check for 'text/html' in types list. See abligh's answer below for deatils on
    // why the DOMStringList bit is needed. We cannot fall back to 'text/plain' as
    // Safari/Edge don't advertise HTML data even if it is available
    types = dt.types;
    if (((types instanceof DOMStringList) && types.contains("text/html")) || (types.indexOf && types.indexOf('text/html') !== -1)) {
    
      // Extract data and pass it to callback
      pastedData = dt.getData('text/html');
      fn(e.target, pastedData);

      // Stop the data from actually being pasted
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }
  
  // Everything else: Move existing element contents to a DocumentFragment for safekeeping
  savedContent = document.createDocumentFragment();
  while(e.target.childNodes.length > 0) {
    savedContent.appendChild(e.target.childNodes[0]);
  }
  
  // Then wait for browser to paste content into it and cleanup
  waitForPastedData(e.target, savedContent, fn);
  return true;
}

function waitForPastedData (elem, savedContent, fn) {

  // If data has been processes by browser, process it
  if (elem.childNodes && elem.childNodes.length > 0) {
  
    // Retrieve pasted content via innerHTML
    // (Alternatively loop through elem.childNodes or elem.getElementsByTagName here)
    var pastedData = elem.innerHTML;
    
    // Restore saved content
    elem.innerHTML = "";
    elem.appendChild(savedContent);
    
    // Call callback
    fn(elem, pastedData);
  }
  
  // Else wait 20ms and try again
  else {
    setTimeout(function () {
      waitForPastedData(elem, savedContent, fn)
    }, 20);
  }
}

export default handlepaste
