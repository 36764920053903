import u from 'umbrellajs'
import api from './api'

export default function() {
  //
  // Set page title
  //
  let h1 = u("#group > h1")
  api.title(h1.text())
}
