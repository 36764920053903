import M from './multiverse'
import contenteditableMaxLength from 'contenteditable-max-length'
import m from 'mithril'
import u from 'umbrellajs'
import api from './api'
import debounce from './debounce';
import handlepaste from './handlepaste'
import imgblat from './imgblat'
import prose from './prose'
import sanitize from './sanitize'
import tasks from './tasks'
import utils from './utils'

import icons from '../icons/*.svg-inline'
import iconsPng from '../icons/*.png'
import '../css/composer'

const placeholders = ["Tell a story here", "<Your vital words>",
  "Huh? Say what?", "64K FREE. READY.", "Haiyo", "It dawns on you..."]

export default {
  //
  // Set up the composer, including hidden elements like the element selector
  // and the prosemirror text editor.
  //
  oninit: function () {
    this.edited = false
    this.targets = null
    this.frames = {}
    this.frameSel = []
    this.extendedFrameSel = []
    this.mode = null
    this.ghost = null
    this.focus = null
    this.autoSave = debounce(
			async finalize => {
        if (this.edited || finalize) {
					this.edited = false
          try {
            this.post.title = document.getElementById("title").value
            this.post.summary = this.summaryProse.toHTML()
            this.post.content.children.map(c => {
              if (c.tag === 'html') {
                c.text = c.vnode.state.prose.toHTML()
              }
            })
            let p = await tasks.savePost(this.post, finalize && this.frames[this.post.frame_id])
            if (!finalize) {
              window.history.replaceState(null, '', '/compose/' + p.stub)
            }
            this.post.id = p.id
            return p
          } catch (e) {
            this.edited = true
          }
        }
			}, 3000)
    this.prose = prose(() => this.onTextInput())
    this.selDiv = u(`<div class="selection">
        <svg><circle r="4" fill="#fff" stroke-width="0.6"></circle></svg>
        <svg><circle r="4" fill="#fff" stroke-width="0.6"></circle></svg>
        <svg><circle r="4" fill="#fff" stroke-width="0.6"></circle></svg>
        <svg><circle r="4" fill="#fff" stroke-width="0.6"></circle></svg>
      </div>`).first()
    this.hoverDiv = u(`<div class="selection"></div>`).first()

    //
    // Load a post if the URL indicates that we are editing one.
    //
    let postUrl, key = m.route.param("key")
    if (key) {
      postUrl = key.split("/")
      if (postUrl.length === 1) {
        postUrl.unshift(api.profile.username)
      }
      this.url = postUrl.join("/")
    }

    if (!postUrl) {
      api.title("Compose a post")
      this.post = {published: false, author: api.author(), groups: {}, images: {},
        visibility: "draft", content: {layout: 1, children:
          [{tag: "html", x: 0, y: 0, text: ""}]}}
    } else {
      api.title("Edit a post")
      api.get("posts/u/" + postUrl.join("/u/"), api.token).
        then(obj => {
          if (obj.author.username !== api.profile.username) {
            api.get("frames/id/" + obj.frame_id).then(fr => {
              this.frames[fr.id] = fr.definition
            })
          }
          let groups = {}
          obj.groups.map(g => groups[g.url] = g)
          this.post = {id: obj.id, title: obj.title, summary: obj.summary, images: {},
            frame_id: obj.frame_id, author: api.author(obj.author), published: obj.published,
            visibility: obj.visibility, content: obj.content, flags: obj.flags, groups}
        })
    }

    //
    // Load all the frames, both for displaying the preview and for
    // the frame selector.
    //
    api.getFrames().
      then(obj => {
        this.frameSel = obj.frames.slice(0, 3) || []
        this.extendedFrameSel = obj.frames.slice(3)
        obj.frames.forEach(fr => this.frames[fr.id] = fr.definition)
        if (this.post && !this.post.frame_id) {
          this.post.frame_id = obj.frames[0].id
        }
        this.finalizeEvent()
      })
  },
  //
  // Mouse handlers for moving, resizing and editing elements.
  //
  oncreate: function(vnode) {

    let mousedown = (function (e) {
      //
      // Mouse down: are we selecting an element - or resizing from its edge?
      //
      if (this.ghost !== null) {
        e.preventDefault()
        return
      }
      let def = null, focus = this.getFocused()
      detectcursor(e, focus?.ele)
      if (this.mode?.hover) {
        let ele = this.mode?.ele
        if (focus !== null && this.mode?.cursor !== 'grab') {
          def = focus.def
          ele = focus.ele
        }
        if (this.mode === null || this.mode.cursor === 'grab') {
          let ary = this.post.content.children, id = null
          if (!def) {
            id = ary.findIndex(c => c.vnode?.dom === e.target)
            def = ary[id]
            ele = e.target
          }
          this.mode = {cursor: 'grabbing', moved: this.focus === null}
          this.focus = {def}
        }
        this.mode.ow = ele.offsetWidth
        this.mode.oh = ele.offsetHeight
        this.mode.ox = ele.offsetLeft
        this.mode.oy = ele.offsetTop
        this.mode.x = e.pageX
        this.mode.y = e.pageY
        this.mode.click = true
        this.mode.ele = ele
        // console.log(this.mode)
      } else {
        if (!u(e.target).closest('.pen-menu, #toolbars').first()) {
          this.focus = null
          this.mode = null
        }
      }
      this.finalizeEvent()
    }).bind(this)

    let mouseup = (function (e) {
      //
      // Mouse up: stop all of the action begun above.
      //
      if (this.ghost !== null) {
        if (this.ghost.moved) {
          let drop = document.elementFromPoint(e.clientX, e.clientY)
          if (drop) {
            let preview = u(drop).closest('#preview').first()
            if (preview) {
              this.newElement(this.ghost)
            }
          }
        } else {
          this.ghost.moved = true
        }
      }
      if (this.mode?.hover) {
        this.mode.click = false
      } else {
        this.mode = null
      }
      this.finalizeEvent()
    }).bind(this)

    let detectcursor = (function (e, ele = null) {
      //
      // Detect edge hover to change the cursor.
      //
      let cursor = 'grab', dir = ''
      if (ele) {
        let edge = ele.parentNode.getBoundingClientRect()
        let x = e.clientX - edge.left
        let y = e.clientY - edge.top
        let relx = x - ele.offsetLeft,
            rely = y - ele.offsetTop
        if (relx >= -M.edge && relx <= ele.offsetWidth + M.edge) {
          if (Math.abs(rely) <= M.edge) {
            dir += 'n'
          } if (Math.abs(y - (ele.offsetTop + ele.offsetHeight)) <= M.edge) {
            dir += 's'
          }
        }
        if (rely >= -M.edge && rely <= ele.offsetHeight + M.edge) {
          if (Math.abs(relx) <= M.edge) {
            dir += 'w'
          } if (Math.abs(x - (ele.offsetLeft + ele.offsetWidth)) <= M.edge) {
            dir += 'e'
          }
        }
      }

      if (dir) {
        cursor = dir + '-resize'
      } else {
        ele = u(e.target).closest('.box-element').first()
      }

      this.mode = ele ? {cursor: cursor, hover: true, ele} : null
    }).bind(this)

    let mousemove = (function(e) {
      //
      // Mouse move
      //
      if (this.ghost !== null) {
        this.ghost.moved = true
        this.setGhost(e)
        e.preventDefault()
      } else if (this.focus !== null) {
        let {def, ele} = this.getFocused()
        if (this.mode?.cursor === 'grabbing') {
          //
          // Dragging the element around.
          //
          if (this.post.content.layout === 1) {
            if (!this.mode.moved) {
              let ary = this.post.content.children
              let id = ary.findIndex(c => c.vnode?.dom === e.target)
              ary.push(ary.splice(id, 1)[0])
            }
            let x = (e.pageX - this.mode.x) + this.mode.ox
            let y = (e.pageY - this.mode.y) + this.mode.oy
            if (ele.offsetWidth + x > 700) { x = 700 - ele.offsetWidth }
            if (x < 0) { x = 0 }
            if (y < 0) { y = 0 }
            def.x = x
            def.y = y
            this.markEdited()
          }
          this.mode.moved = true
          e.preventDefault()
        } else if (this.mode?.click) {
          //
          // Resizing the element - commented out code is height.
          //
          let x = (e.pageX - this.mode.x)
          // let y = (e.pageY - this.mode.y) + this.mode.oy
          // if (y < 0) { y = 0 }
          // if (state.mode.cursor === 'n-resize' || state.mode.cursor === 'nw-resize' ||
          //     state.mode.cursor === 'ne-resize') {
          //   state.focus.props.y = y + state.mode.y
          //   state.focus.props.height = state.mode.focus.height + (state.mode.focus.y - state.focus.props.y)
          // }
          if (this.mode.cursor === 'w-resize' || this.mode.cursor === 'nw-resize' ||
              this.mode.cursor === 'sw-resize') {
            x = Math.min(Math.max(x, -this.mode.ox), this.mode.ow)
            def.x = this.mode.ox + x
            def.width = this.mode.ow - x
          }
          // if (state.mode.cursor === 's-resize' || state.mode.cursor === 'sw-resize' ||
          //     state.mode.cursor === 'se-resize') {
          //   state.focus.props.height = (y - state.mode.focus.y) + (state.mode.focus.height - state.mode.y)
          // }
          if (this.mode.cursor === 'e-resize' || this.mode.cursor === 'ne-resize' ||
              this.mode.cursor === 'se-resize') {
            x = Math.min(x, 700 - (this.mode.ox + this.mode.ow))
            def.width = this.mode.ow + x
          }
          if (def.width < 1) { def.width = 1 }
          this.mode.moved = true
          this.finalizeEvent()
          e.preventDefault()
        } else if (ele?.parentNode) {
          detectcursor(e, ele)
          this.finalizeEvent()
        }
      } else {
        detectcursor(e)
        this.finalizeEvent()
      }
    }).bind(this)

    u(document).
    on('pointerdown', mousedown).
    on('pointerup', mouseup).
    on('pointermove', mousemove).
    on('keydown', e => {
      if (this.focus !== null) {
        switch (e.which) {
          case 27: // Escape
            this.focus = null
            this.finalizeEvent()
            e.preventDefault()
          break
          case 37: // Left
          case 38: // Up
          case 39: // Right
          case 40: // Down
            if (e.target.contentEditable !== "true" && this.post.content.layout === 1) {
              let focus = this.focus.def
              if (e.which === 37 && focus.x > 0) {
                focus.x -= 1
              } else if (e.which === 38 && focus.y > 0) {
                focus.y -= 1
              } else if (e.which === 39) {
                focus.x += 1
              } else if (e.which === 40) {
                focus.y += 1
              }
            }
          break;
          case 8: // Backspace (Mac Del)
          case 46: // Del
            if (e.target.contentEditable !== "true") {
              this.trashFocusedChild()
              e.preventDefault()
            }
          break
        }
      }
    })

    //
    // Setup drag and drop target.
    //
    let prevd = e => {
      e.preventDefault()
      e.stopPropagation()
    }
    u(document).on('drop', e => {
      this.newImageFn = (obj) => {
        this.newElement(Object.assign(obj, {tag: "img", x: e.pageX, y: e.pageY}))
      }
      if (e.dataTransfer.files.length) {
        this.loadImages(e.dataTransfer.files)
        e.preventDefault()
      } else {
        handlepaste(e, async (div, pasted) => {
          let ele = sanitize.html(pasted)
          if (ele.tag === 'img') {
            let res = await api.fetch("remote?url=" + encodeURIComponent(ele.src))
            let blob = await res.blob()
            blob.name = (new URL(ele.src)).pathname.split('/').pop()
            this.loadImages([blob])
          } else {
            ele.x = e.pageX
            ele.y = e.pageY
            this.newElement(ele)
          }
        })
      }
    }).on('dragenter', prevd, false).
       on('dragleave', prevd, false).
       on('dragover', prevd, false)
  },
  onupdate: function() {
    this.resizePreview()
  },
  onbeforeremove: function() {
    this.save(false)
  },
  save: async function(finalize) {
    try {
      this.saved = true
      u(document).off('pointerdown pointermove pointerup keydown drop dragenter dragleave dragover')
      //
      // Get available groups to post to
      //
      this.targets = {}
      api.getGroups().then(groups => {
        this.targets.groups = []
        if (api.profile?.admin) {
          this.targets.groups = this.targets.groups.concat(groups.curated)
        }
        this.targets.groups = this.targets.groups.concat(groups.public)
        this.finalizeEvent()
      })
      //
      // Begin saving the post and generating images
      // in the background
      //
      await this.autoSave.now(finalize)
    } catch {
      this.autoSave.cancel()
    }
  },
  publish: async function() {
    let p = await this.autoSave.promise()
    let groups = []
    let group = document.querySelector('input[name="group"]:checked')?.value
    for (let exGroup in this.post.groups) {
			if (group != exGroup) {
				groups.push({target: exGroup, state: 0})
			} else {
        group = null
      }
    }
    if (group) {
			groups.push({target: group, state: 1})
    }

    if (this.post.visibility !== "draft") {
			p = await api.publishPost(this.post.id,
				{update: false, visibility: this.post.visibility, groups, externals: []})
			m.route.set(`/${this.post.author.username}/${p.stub}`)
    } else {
			m.route.set(`/home`)
    }
  },
  finalizeEvent: function() {
    u("#preview").attr({style: `cursor: ${this.mode?.cursor}`})
    m.redraw()
  },
  getFocused: function() {
    return this.focus ? {
      active: this.focus.active,
      ele: this.focus.def?.vnode?.dom,
      def: this.focus.def} : {}
  },
  alignDivs: function (divSrc, divTarget) {
    if (divTarget.parentNode !== divSrc.parentNode)
    {
      divTarget.parentNode?.removeChild(divTarget)
      divSrc.parentNode.appendChild(divTarget)
    }
    divTarget.style.left = divSrc.offsetLeft + 'px'
    divTarget.style.top = divSrc.offsetTop + 'px'
    divTarget.style.width = divSrc.offsetWidth + 'px'
    divTarget.style.height = divSrc.offsetHeight + 'px'
  },
  //
  // Keep the selection and editor elements the same size as the
  // focused element.
  //
  trackFocused: function() {
    let {def, ele} = this.getFocused()
    if (ele) {
      this.alignDivs(ele, this.selDiv)

      //
      // Position the toolbars
      //
      let tb = document.getElementById("toolbars")
      let width = 800
      let optionLeft = (ele.offsetLeft > 140 ? 0 : 540)
      let rect = utils.pos(ele.parentNode)
      let x = (ele.offsetLeft + (ele.offsetWidth / 2)) - (width / 2)
      tb.style = `top:${(rect.top + ele.offsetTop) - 46}px;left:${rect.left + x}px;width:${width}px`
      tb.children[1].style = `position: absolute; left: ${optionLeft}px`
      return ele
    }
  },
  //
  // Resize the comic log based on the contents. Width and height are hardcoded
  // for comic logs. For other 'flow'-type posts, the width and height are
  // automatic when viewed - however, we need to resize the frame when 
  // editing those post types here, because I'm using 'float: left; clear: left'
  // to prevent block elements from expanding to 100% width.
  //
  resizePreview: function() {
    //
    // Draw selection box and position the toolbars.
    //
    let focusEle = null
    if (this.focus !== null) {
      focusEle = this.trackFocused()
    } else if (this.selDiv?.parentNode) {
      this.selDiv.parentNode.removeChild(this.selDiv)
    }
    if (this.mode?.hover && focusEle !== this.mode.ele) {
      this.alignDivs(this.mode.ele, this.hoverDiv)
    } else if (this.hoverDiv?.parentNode) {
      this.hoverDiv.parentNode.removeChild(this.hoverDiv)
    }

    //
    // Resize a parent box-block
    //
    u("#composer .box-block").each(node => {
      if (this.post.content.layout <= 1) {
        let maxw = 0, maxh = 0
        let children = node.children
        for (let i = 0; i < children.length; i++) {
          let c = children[i]
          if (c !== this.selDiv && c !== this.hoverDiv) {
            let w = c.offsetLeft + c.offsetWidth
            let h = c.offsetTop + c.offsetHeight
            if (maxw < w)
              maxw = w
            if (maxh < h)
              maxh = h
          }
        }
        this.post.content.width = 700 // maxw
        this.post.content.height = maxh
        // node.style.width = `${maxw}px`
        node.style.height = `${maxh}px`
      } else {
        delete this.post.content.width
        delete this.post.content.height
        node.style.height = "auto";
        // node.style.width = "auto";
      }
    })
  },
  markEdited() {
    this.edited = true
    this.autoSave(false)
    m.redraw()
  },
  copyFocusedChild: function () {
    let ary = this.post.content.children
    let id = ary.findIndex(c => c === this.focus.def)
    let c = ary[id], v = c.vnode
    delete c.vnode
    let def = Object.assign({}, c)
    def.y += 10 
    ary.splice(id + 1, 0, def)
    c.vnode = v
    this.focus = {def}
    this.markEdited()
  },
  trashFocusedChild: function () {
    if (confirm("You sure you want to trash this item?")) {
      this.post.content.children =
        this.post.content.children.filter(c => c !== this.focus.def)
      this.focus = null
      this.markEdited()
    }
  },
  //
  // Image and text creation and loading handlers.
  //
  newImage: function (fn) {
    this.newImageFn = fn
    u("#new-file").first().click()
  },
  newFile: function (e) {
    this.loadImages(e.target.files)
  },
  loadImages: function (files) {
    imgblat(files, {maxWidth: 1024}, this.newImageFn)
    this.markEdited()
  },
  place: function (at) {
    let size = utils.pos(document.getElementsByClassName("box-block")[0])
    at.x -= size.left
    if (at.x < 0) at.x = 0
    if (at.x > 600) at.x = 600
    at.y -= size.top
    if (at.y < 0) at.y = 0
    return at
  },
  newElement: function(ghost) {
    let fn = (obj) => {
      let def = Object.assign(obj, {tag: ghost.tag, x: ghost.x, y: ghost.y})
      if (def.tag == 'html') {
        def.text = ghost.text || ""
      } else if (ghost.src) {
        def.src = ghost.src
      }
      this.ghost = null
      this.place(def)
      this.post.content.children.push(def)
      this.focus = {def}
      this.markEdited()
    }
    if (ghost.tag === "img" && !ghost.src) {
      this.newImage(fn)
    } else {
      fn({})
    }
  },
  setGhost: function (e, tag) {
    if (tag) {
      this.ghost = {tag}
    }
    this.ghost.x = e.pageX - 140
    this.ghost.y = e.pageY - 14
    this.finalizeEvent()
  },
  toolbarChange(action, obj) {
    switch (action) {
      case 'meta':
        if (obj.active === 'trash') {
          this.trashFocusedChild()
        } else if (obj.active === 'copy') {
          this.copyFocusedChild()
        } else {
          Object.assign(this.focus, obj)
        }
      return

      case 'merge':
        obj = obj || this.focus.hover
        delete this.focus.hover
        Object.assign(this.focus.def, obj)
        this.markEdited()
      break

      case 'hover':
        if (!obj) {
          delete this.focus.hover
        } else {
          this.focus.hover = obj
        }
      break
    }
  },
  onTextInput() {
    this.markEdited()
  },
  mario(frame) {
    let sel = this.post.frame_id === frame.id
    return <div class={sel ? 'sel' : ''} tabindex={sel ? "0" : "-1"}
      onpointerdown={e => this.post.frame_id = frame.id}>
        {M.box('box', frame.definition.main,
          M.text('p', frame.definition.main.text, 'Aa', {}))}</div>
  },
  //
  // Actual HTML for the editor.
  //
  view: function() {
    if (this.targets) {
      return <div id="publish">
				<div class="banner">
					<button class="publish" onpointerdown={e => {e.target.disabled = true; this.publish()}}>Save</button>
				</div>

        <p>Publish to:</p>
        <ul class="targets">
          {M.publishTo.map(target => <li>
            <div>
              <input type="radio" id={target.id} name="viz" value={target.id}
                onclick={() => this.post.visibility = target.id}
                oncreate={v => v.dom.checked = (target.id == this.post.visibility)} />
              <i />
            </div>
            <p>
            <label for={target.id}>
              {target.name}<br />
							<span class="note">{target.note}</span>
            </label>
            </p>
          </li>)}
        </ul>
        {this.targets.groups && <div class={`groups groups-${this.post.visibility}`}>
          <p>Post to a group:<br />
						<span class="note">Unlisted and private posts cannot be posted to a group.</span>
          </p>
					<ul class="targets">
						{this.targets.groups.map(target => {
              let click = e => {
                let radio = document.getElementById(target.url)
                radio.checked = !radio.checked
                e.preventDefault()
              }
              return <li>
								<div>
									<input type="radio" id={target.url} name="group"
                    value={target.url} onclick={click} oncreate={v => v.dom.checked = !!this.post.groups[target.url]} />
									<i />
								</div>
								<p>
								<label for={target.url} onclick={click}>
									{target.name}<br />
									<span class="note">{target.caption}</span>
								</label>
								</p>
							</li>
            })}
					</ul>
				</div>}
      </div>
    }

    return (this.post && <div id="composer">
      <div class="banner">
        <button class="publish" disabled={this.post.content.children.length === 0}
          onpointerdown={e => {e.target.disabled = true; this.save(true)}}>Save</button>
      </div>

      <input type="file" accept="image/*" name="new-file" id="new-file"
        onchange={e => this.newFile(e)}
        style="display:none" />

      <div class="col2">
        <div class="meta">
          <div class="title">
            <input type="text" id="title" name="title" class="compose" pattern=".*\S.*" maxlength="100"
              autocomplete="off" autocorrect="off" autocapitalize="none" placeholder="Title"
              oncreate={v => v.dom.value = this.post.title || ""}
              oninput={() => this.onTextInput()} />
          </div>
          <div class="summary">
            {m("div", {id: 'summary', class: 'compose',
              oncreate: v => {
                contenteditableMaxLength({element: v.dom, maxLength: 512})
                this.summaryProse = this.prose.editor(v.dom, this.post.summary || "", false, "Caption")
              },
              onbeforeremove: v => this.summaryProse?.destroy()
            })}
          </div>
       </div>

        <div class="frame">
          <div class='mario grid-4'>
            {this.frameSel.map(frame => this.mario(frame))}
            {this.extendedFrameSel.length > 0 && <div class="extended"
              onclick={e => u(e.target).closest(".extended").children(".drawer").toggleClass('open')}>
              {M.box('box', M.defaultFrame.main,
                M.text('p', M.defaultFrame.main.text, '...', {}))}
              <div class="drawer">
                <svg class="tri" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <polygon points="8.5 0 0 17 17 17" style="fill: var(--mvt-toolback)" />
                </svg>
                <div class="mario grid-5 mvt_shadow">
                  {this.extendedFrameSel.map(frame => this.mario(frame))}
                </div>
              </div>
              </div>}
          </div>
        </div>
      </div>

      <div id="createbar">
        <div class="stay">
          <div class="create">
            <button id="new-text" onpointerdown={e => this.setGhost(e, "html")}>
              {m.trust(icons['composer-text'])}
              <p>text</p>
            </button>
          </div>
          <div class="create">
            <button id="new-image" onpointerdown={e => this.setGhost(e, "img")}>
              {m.trust(icons['composer-image'])}
              <p>image</p>
            </button>
          </div>
          <div class="create">
            <button id="new-gif" onpointerdown={e => this.setGhost(e, "img")}>
              {m.trust(icons['composer-gif'])}
              <p>gif</p>
            </button>
          </div>
        </div>
      </div>

      <div id="preview" class="post-body">
        {M.post(this.url, this.frames[this.post.frame_id], this.post.author, this.post,
            {oncreate: (v, c) => c.tag === 'html' && (v.state.prose = this.prose.editor(v.dom, c.text, true, utils.randomIn(placeholders))),
             onbeforeremove: v => v.state.prose?.destroy()})}
      </div>

      {this.focus && M.toolbar.block(this.getFocused(), (action, obj) => this.toolbarChange(action, obj))}
      {this.ghost && <div class="ghost" x={this.ghost.x} y={this.ghost.y}>
        {this.ghost.tag === "html" ? <p>Click again to place it down</p> : <img src={iconsPng["place-img"]} />}
      </div>}
    </div>)
  }
}
