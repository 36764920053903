import M from './multiverse'
import m from 'mithril'
import op from 'object-path'
import u from 'umbrellajs'
import api from './api'
import utils from './utils'

import icons from '../icons/*.svg'
import '../css/box'

const state = {
  work: {page: 0},
  box: M.defaultFrame,
  frames: [],

  //
  // Dialog for the box editor flow
  //
  // main box color
  // main box font
  // name box color
  // name box font
  //
  flow: [
    {title: 'Select Your Base', tool: 'mario', summary: [
      <p>Select a base style that you then customize from. Hover over each of
         them to see the style applied to the box below.</p>,
      <p>Click the style to move ahead - fine tuning the colors,
         background, font, etc.</p>
    ]},
    {title: 'Style Your Box', key: 'main', tool: 'box', active: 'fill', summary:
      <p>Customize it with patterns, rounded corners, colored borders, and many more.</p>},
    {title: 'Customize the Font', key: 'main.text', tool: 'text', active: 'fill', summary:
      <p>Time to style your main text here. This is the font and color that all of
         your comments will show up with.</p>},
    {title: 'Style Your Name Tag', key: 'title', tool: 'box', active: 'fill', summary:
      <p>Your name tag can also be styled completely differently, if you want!
         With its own outlines and shadows as well. Try it out.</p>},
    {title: 'And Your Font', key: 'title.text', tool: 'text', active: 'fill', summary:
      <p>Ok, last one! A chance to alter the font and color for your name tag.</p>}
  ],

  messages: [
    "Today's a beautiful day. It's finally sunny again and perfect for hanging out in the park",
    "During <em>Show Me Your Guts</em>, we would all gather around the bonfire, and either share something we've never said out loud before, or do something we've never done in front of others, no exceptions.",
    "Comic logs bring whitespace to blogging. This has always been possible with images (rip spacer.gif) and in webcomics - not sure why widely-spaced layouts didn't come to blogging sooner.",
    "This character was originally introduced into the Webdings font as an exclamation mark in the style of the rude boy logo found on records by &#8220;The Specials&#8221;. This levitating man was known as Walt Jabsco."
  ],

  //
  // Change from page-to-page of the flow above
  //
  setPage: function (page) {
    //
    // Set up toolbars
    //
    if (this.work.page !== page) {
      let scratch, meta = this.flow[page - 1]
      if (meta.key) {
        let working = op.get(this.box, meta.key)
        scratch = utils.dup(working)
      }
      this.work = {page, meta, scratch}
    }
  },
    
  //
  // Load and save the box to/from localStorage.
  //
  saveBox: function () {
    this.box.header = M.calcHeader(this.box)
    api.saveFrame(m.route.param("key"), this.box).
      then(() => m.route.set("/profile"))
  },

  loadBox: function (frameId) {
    if (frameId) {
      api.title("Edit a frame")
      api.getFrame(frameId).
        then(frame => state.applyBox(frame.definition))
    } else {
      api.title("Create a frame")
      api.getFrames().then(list => {
        state.frames = list.frames
        state.setPage(1)
      })
    }
  },

  //
  // Apply styles to the box and the dialogs. (Not to the toolbar.)
  //
  applyBox: function (box) {
    if (box) {
      this.box = utils.dup(box)
      this.work.page = 0
      this.setPage(2)
    }
    m.redraw()
  }
}

function toolbar_onChange(k, v) {
  let working, meta = state.work.meta
  switch (k) {
    case 'meta':
      Object.assign(meta, v)
    return

    case 'preview':
      if (state.work.meta.tool === 'mario') {
        if (!v) {
          state.work.scratch = utils.dup(state.box)
        } else {
          state.work.scratch = utils.dup(v)
        }
      }
    break

    case 'base':
      state.applyBox(v)
    return

    case 'merge':
    case 'merge-box':
      if (!v) {
        v = state.work.hover
        delete state.work.hover
      }
      Object.assign(state.work.scratch[meta.active], v)
      working = utils.dup(state.work.scratch)
      op.set(state.box, meta.key, working)
    break

    case 'hover':
      if (!v) {
        delete state.work.hover
      } else {
        state.work.hover = v
      }
      // console.log(state.work.scratch)
    break
  }
  state.applyBox()
  return working
}

export default {
  oninit: function () {
    this.message = m.trust(utils.randomIn(state.messages))
    state.loadBox(m.route.param("key"))
  },
  view: function() {
    //
    // Draw the box
    //
    let t = state.box
    if (state.work.scratch) {
      let ref = t = utils.dup(t)
      if (state.work.meta.key) {
        ref = op.get(t, state.work.meta.key)
      }
      // console.log([ref, state.work.meta.key, state.work.scratch])
      Object.assign(ref, utils.dup(state.work.scratch))
      // console.log(ref)
      if (state.work.hover && state.work.meta.active) {
        Object.assign(ref[state.work.meta.active], utils.dup(state.work.hover))
      }
    }

    return <div class="main">
      {state.work.page > 0 ?
        <div id="canvas">
          <div id="right">
            <div id="box" class="post">
              {state.work.meta && [M.title(t, state.work.meta)]}
              {M.frame(t, api.author(), this.message, 1)}
            </div>
            <div id="next">
              <div>
                <div display={state.work.page < state.flow.length ? '' : 'none'}>
                  <a href='#' onclick={() => state.setPage(state.work.page + 1)}>Next</a>
                </div>
                <div display={state.work.page === state.flow.length ? '' : 'none'}>
                  <a href='#' onclick={() => state.saveBox()}>Save</a>
                </div>
              </div>
            </div>
          </div>
          <div id="toolbars">
            {M.toolbar.selector(state.frames, state.work.meta, state.work.scratch, toolbar_onChange)}
          </div>
          <div id="surface">
            <div id="rect"></div>
            <div id="prev">
              <div class='prev' display={state.work.page > 1 ? '' : 'none'}>
                <a href='#' onclick={() => state.setPage(state.work.page - 1)}>
                  <img src={icons['arrow_prev']} />
                  <span>Previous</span></a>
              </div>
            </div>
          </div>
        </div> : []}
      </div>
  }
}
