import M from './multiverse'
import m from 'mithril'
import api from './api'
import imgblat from './imgblat'
import images from '../images/*.png'

var state = {}

export default {
  oninit: function () {
    api.title("Images")
  },

  view: function() {
    return <div id="image-drop">
      <img src={images["xtra-drop"]} />
    </div>
  }
}
