import 'regenerator-runtime/runtime'
import domify from 'domify'
import iro from '@jaames/iro'
import m from 'mithril'
import u from 'umbrellajs'
import fonts from '../../common/defs/fonts.json'

import api from './api'
import frames from './frames'
import utils from './utils'
import patterns from '../patterns/1/pattern-*.svg'
import icons from '../icons/*.svg'

//
// multiverse globals
//
var M = {
  edge: 10,

  //
  // Default publish targets
  //
  publishTo: [
    {id: "public", name: "multiverse"},
    {id: "draft", name: "keep these changes as a draft"},
    {id: "private", name: "private to me"},
    {id: "unlisted", name: "unlisted",
     note: "only those who have the link can view this post - will not show up to followers."}
  ],
     
  //
  // Default box settings
  //
  defaultFrame: {
    main: {
      fill: {
        type: 'Gradient',
        direction: 'vertical',
        color: '#FAE9FFFF',
        back: '#FFFFFFF2'
      },
      border: {
        color: '#000000',
        style: 'solid_1px',
        radius: 0
      },
      shadow: {
        style: 'plain',
        type: 'Solid',
        color: '#B6B5A8A5'
      },
      highlight: {
        style: 'plain',
        type: 'None'
      },
      text: {
        font: {
          family: 'Roboto'
        },
        fill: {
          type: 'Solid',
          color: '#000000'
        }
      }
    },
    title: {
      fill: {
        type: 'Solid',
        color: '#FFFFFF'
      },
      border: {
        color: '#000000',
        style: 'solid_1px',
        radius: 0
      },
      shadow: {
        style: 'plain',
        type: 'None'
      },
      highlight: {
        style: 'plain',
        type: 'None'
      },
      text: {
        font: {
          family: 'Red Rose'
        },
        fill: {
          type: 'Solid',
          color: '#000000'
        }
      }
    }
  },

  //
  // Default font choices
  //
  fonts: fonts.builtin,

  //
  // Default color palette
  //
  colors: [
    {name: 'Lemon Chiffon', code: '#F5EEC1'},
    {name: 'Peach Fuzz',    code: '#F2D99E'},
    {name: 'Dijon',         code: '#E1BE51'},
    {name: 'Soup',          code: '#D9864A'},
    {name: 'Rhubarb',       code: '#CB564A'},
    {name: 'Blush',         code: '#DF95A7'},
    {name: 'Bold Pink',     code: '#DD3977'},
    {name: 'Lavender',      code: '#CBC7F1'},
    {name: 'Aged Orchid',   code: '#B675C6'},
    {name: 'Purple',        code: '#6730B7'},
    {name: 'Pitch Blue',    code: '#0C192E'},
    {name: 'Night Blue',    code: '#210080'},
    {name: 'Blue',          code: '#2847E0'},
    {name: 'Cool Water',    code: '#84CEDD'},
    {name: 'Mint',          code: '#94DBBF'},
    {name: 'Lime',          code: '#C9F295'},
    {name: 'Marine',        code: '#1AB69A'},
    {name: 'Forest',        code: '#0F5B0F'},
    {name: 'Slime',         code: '#846B12'},
    {name: 'Mocha',         code: '#3A231B'},
    {name: 'Charcoal',      code: '#201C1B'},
    {name: 'Dim',           code: '#B6B5A8'},
    {name: 'Eeeeee',        code: '#EEEEEE'},
    {name: 'Egg Shell',     code: '#FAFAFA'}
  ],

  //
  // Default gradients
  //
  gradients: [
    {color: '#EBDAAF', back: '#00FFFF1A'},
    {color: '#3AB1F3', back: '#FFFFFF'},
    {color: '#000000', back: '#6E30E7'},
    {color: '#FAFEFF', back: '#00000000'},
    {color: '#550C07', back: '#FFAF663E'},
    {color: '#E2B617', back: '#FFAF663E'},
    {color: '#9E3FD0', back: '#C82C61'},
    {color: '#C32C43', back: '#EA9039'},
    {color: '#357B71', back: '#58C3A2'},
    {color: '#E741B9', back: '#FEB553'},
    {color: '#8239DE', back: '#34EFD92F'},
    {color: '#127042', back: '#29E7D02F'}
  ],

  //
  // Color combos
  //
  combos: [
    {color: '#F5EEC1', back: '#F5DDC1'},
    {color: '#DAFEDA', back: '#95F2D6'},
    {color: '#C1F5E8', back: '#C1E8F5'},
    {color: '#F3B491', back: '#F5C4C1'},
    {color: '#B5E0DD', back: '#D0BEF7'},
    {color: '#D4C8D8', back: '#A6C4C5'},
    {color: '#F4DCE6', back: '#C9F3EE'},
    {color: '#FFD1C9', back: '#EDEDE6'},
    {color: '#E3BDF1', back: '#C8C7F1'},
    {color: '#AAF235', back: '#F1EA4B'},
    {color: '#46BBC3', back: '#4FD5C5'},
    {color: '#EE6297', back: '#EE7C47'},
    // {color: '#FF9457', back: '#FFBD6F'},
    {color: '#02385D', back: '#8F297F'},
    {color: '#162367', back: '#333645'},
    {color: '#70694D', back: '#56523A'},
    {color: '#007A64', back: '#024E40'},
    {color: '#650000', back: '#310101'},
    {color: '#43423E', back: '#0E0106'}
  ],

  //
  // Default patterns
  //
  patterns: [
    {name: 'Confetti',  path: 'confetti'},
    {name: 'Basket',    path: 'basket'},
    {name: 'Polka Dot', path: 'polka2'},
    {name: 'Square',    path: 'square'},
    {name: 'Wave',      path: 'wave'},
    {name: 'Dense',     path: 'dense'},
    {name: 'Polka',     path: 'polka'},
    {name: 'Basket',    path: 'striped2'},
    {name: 'Basket',    path: 'weave2'},
    {name: 'Basket',    path: 'diamond'},
    {name: 'Basket',    path: 'scales2'},
    {name: 'Basket',    path: 'striped3'},
    {name: 'Basket',    path: 'weave'},
    {name: 'Basket',    path: 'fleurs'},
    {name: 'Basket',    path: 'scales'},
    {name: 'Basket',    path: 'striped'},
    {name: 'Basket',    path: 'zig'},
    {name: 'Basket',    path: 'hatch'},
    {name: 'Basket',    path: 'square2'},
    {name: 'Basket',    path: 'tri'}
  ],

  //
  // Gradient directions
  //
  directions: ['vertical', 'horizontal', 'diagonal_left', 'diagonal_right'],

  //
  // Border styles
  //
  borders: ['dotted_1px', 'dotted_2px', 'dotted_4px', 'solid_1px', 'solid_2px',
    'solid_4px', 'solid_6px', 'solid_8px', 'solid_12px', 'none'],

  //
  // Shadow styles
  //
  shadows: ['plain', 'blur_1px', 'blur_2px', 'blur_4px', 'blur_8px'],
  blockShadows: ['none', 'plain', 'blur_1px', 'blur_2px', 'blur_4px', 'blur_8px'],

  //
  // Toolbar state
  //
  focus: null,
  customize: null,

  //
  // Toolbar builder
  //
  toolbar: {
    //
    // BLOCK LEVEL TOOLBAR AND OPTION PANES
    //
    block: function (obj, onchange) {
      let options
      switch (obj.def.tag) {
        case 'html':
          options = [['Background', 'back'], ['Color', 'fore'],
            ['Font', 'font'], ['Outline', 'border'], ['Shadow', 'shadow'],
            ['Copy', 'copy'], ['Trash', 'trash']]
        break
        default:
          options = [['Outline', 'border'], ['Shadow', 'shadow'],
            ['Copy', 'copy'], ['Trash', 'trash']]
        break
      }

      return (options && <div id="toolbars">
        <div class='options'>
        {
          options.map(opt => <div class='sep'><button class={obj.active === opt[1] ? 'sel' : null}
            onpointerdown={e => onchange('meta', {active: opt[1]})}>
              <img src={icons[`toolbar_${opt[0]}`]} />
              {opt[1][0] !== '_' && opt[0]}</button></div>)
        }
        </div>
        <div>
          {M.toolbar.blockOptions(obj, onchange)}
        </div>
      </div>)
    },

    blockOptions: function (obj, onchange) {
      switch (obj.active) {
        case 'fore':
        case 'back':
          return M.toolbar.blockColor(obj, obj.def, onchange)
        case 'font':
          return M.toolbar.font(obj, obj.def, 'font', onchange)
        case 'border':
          return M.toolbar.blockBorder(obj, obj.def, onchange)
        case 'shadow':
          return M.toolbar.blockShadow(obj, obj.def, onchange)
      }
      return []
    },

    blockColor: (meta, obj, onchange) => {
      return <div class='details'>
        <div class='pane'>{M.toolbar.solid(meta, obj, meta.active, 'Recent colors', onchange)}</div>
      </div>
    },

    blockBorder: (meta, obj, onchange) => {
      return <div class='details'>
        <div class='pane'>
          <div class='toolbar'>
            <p>Border style</p>
            <div class='borders'>
            {
              M.borders.map(b => <div class='border'><a href='#'
                class={obj.borderStyle === b ? 'sel' : null}
                onpointerover={e => onchange('hover', {borderStyle: b})}
                onpointerout={e => onchange('hover')}
                onpointerdown={e => onchange('merge', {borderStyle: b})}>
                  <img src={icons[`border_${b}`]} /></a></div>)
            }
            </div>
            <p>Border radius</p>
            <div class='slider'>
              <input type='range' class='knob' min='0' max='20' value={obj.radius || 0}
                oninput={e => onchange('merge', {radius: Number(e.target.value)})} />
              <img class='grade' src={icons['slider']} />
            </div>
          </div>
          {M.toolbar.solid(meta, obj, 'stroke', 'Recent colors', onchange)}
        </div>
      </div>
    },

    blockShadow: (meta, obj, onchange) => {
      return <div class='details'>
        <div class='pane'>
          <div class='toolbar'>
            <p>Shadow style</p>
            <div class='palette shadow'>
            {
              M.blockShadows.map(s => <div class={'opt' + (obj.shadow === s ? ' sel' : '')}
                pattern={'url(' + icons[`shadow_style_${s}`] + ')'}
                onpointerover={e => onchange('hover', {shadow: s})}
                onpointerout={e => onchange('hover')}
                onpointerdown={e => onchange('merge', {shadow: s})}>
                  {obj.shadow === s && <img src={icons['check']} />}
                  </div>)
            }
            </div>
          </div>
          {M.toolbar.solid(meta, obj, 'shadowColor', 'Recent colors', onchange)}
        </div>
      </div>
    },

    //
    // FRAME STYLER TOOLBAR AND OPTION PANES
    //
    selector: function (userFrames, meta, obj, onchange) {
      let options
      switch (meta.tool) {
        case 'box':
          options = [['Background', 'fill'], ['Outline', 'border'],
            ['Highlight', 'highlight'], ['Shadow', 'shadow']]
        break
        case 'text':
          options = [['Color', 'fill'], ['Font', 'font']]
        break
      }

      return meta.tool === 'mario' ?

        <div class='mario grid-4'>
        {
          (userFrames.concat(frames)).map(frame => <div
            onpointerover={e => onchange('preview', frame.definition)}
            onpointerout={e => onchange('preview')}
            onpointerdown={e => onchange('base', frame.definition)}>
              {M.box('box', frame.definition.main, 
                M.text('p', frame.definition.main.text, 'Aa', {}))}</div>)
        }
        </div> :

        <div>
          <div class='options'>
          {
            options.map(opt => <div class='sep'><button class={meta.active === opt[1] ? 'sel' : null}
              onpointerdown={e => onchange('meta', {active: opt[1]})}>
                <img src={icons[`toolbar_${opt[0]}`]} />
                {opt[0]}</button></div>)
          }
          </div>
          {M.toolbar.details(meta, obj[meta.active], onchange)}
        </div>
    },

    details: function (meta, obj, onchange) {
      switch (meta.active) {
        case 'fill':
          return M.toolbar.background(meta, obj, onchange)
        case 'border':
          return M.toolbar.outline(meta, obj, onchange)
        case 'shadow':
        case 'highlight':
          return M.toolbar.shadow(meta, obj, onchange)
        case 'font':
          return M.toolbar.font(meta, obj, 'family', onchange)
      }
      return []
    },

    background: (meta, obj, onchange) => {
      return <div class='details'>
        {meta.tool !== 'text' &&
          <div class='tab'>{
            ['Solid', 'Gradient', 'Pattern'].map(text =>
              <button class={obj.type === text ? 'sel' : null} onpointerdown={e =>
                onchange('merge', {type: text})}>{text}</button>)}
          </div>}
        <div class='pane'>{M.toolbar.fill(meta, obj, onchange)}</div>
      </div>
    },

    outline: (meta, obj, onchange) => {
      return <div class='details'>
        <div class='pane'>
          <div class='toolbar'>
            <p>Border style</p>
            <div class='borders'>
            {
              M.borders.map(b => <div class='border'><a href='#'
                class={obj.style === b ? 'sel' : null}
                onpointerover={e => onchange('hover', {style: b})}
                onpointerout={e => onchange('hover')}
                onpointerdown={e => onchange('merge', {style: b})}>
                  <img src={icons[`border_${b}`]} /></a></div>)
            }
            </div>
            <p>Border radius</p>
            <div class='slider'>
              <input type='range' class='knob' min='0' max='20' value={obj.radius}
                oninput={e => onchange('merge-box', {radius: Number(e.target.value)})} />
              <img class='grade' src={icons['slider']} />
            </div>
          </div>
          {M.toolbar.solid(meta, obj, 'color', 'Recent colors', onchange)}
        </div>
      </div>
    },

    shadow: (meta, obj, onchange) => {
      return <div class='details'>
        <div class='tab'>{
          ['None', 'Solid', 'Gradient', 'Pattern'].map(text =>
            <button class={obj.type === text ? 'sel' : null} onpointerdown={e =>
              onchange('merge', {type: text})}>{text}</button>)}
        </div>
        <div class='pane'>
          {obj.type !== 'None' && M.toolbar.shadowStyle(meta, obj, 'style', onchange)}
          {M.toolbar.fill(meta, obj, onchange)}
        </div>
      </div>
    },

    shadowStyle: (meta, obj, key, onchange) => {
      return  <div class='toolbar'>
        <p>Shadow style</p>
        <div class='palette shadow'>
        {
          M.shadows.map(s => <div class={'opt' + (obj[key] === s ? ' sel' : '')}
            pattern={'url(' + icons[`shadow_style_${s}`] + ')'}
            onpointerover={e => onchange('hover', {[key]: s})}
            onpointerout={e => onchange('hover')}
            onpointerdown={e => onchange('merge', {[key]: s})}>
              {obj[key] === s && <img src={icons['check']} />}
              </div>)
        }
        </div>
      </div>
    },

    font: (meta, obj, key, onchange) => {
      return <div class='details'>
        <div class="font-selector">
        {
          M.fonts.map(font => (!font.restricted || api.profile?.admin) &&
            <button font={font.name} class={'opt' + (obj[key] === font.name ? ' sel' : '')}
              onpointerover={e => onchange('hover', {[key]: font.name})}
              onpointerout={e => onchange('hover')}
              onpointerdown={e => onchange('merge', {[key]: font.name})}>{font.name}</button>)
        }
        </div>
      </div>
    },

    fill: (meta, obj, onchange) => {
      switch (obj.type) {
        case 'Solid': 
          return M.toolbar.solid(meta, obj, 'color', 'Recent colors', onchange)
        case 'Gradient':
          return M.toolbar.gradient(meta, obj, 'Gradient direction', onchange)
        case 'Pattern':
          return M.toolbar.pattern(meta, obj, onchange)
      }
      return []
    },

    picker: (meta, obj, title, opts, onchange) => {
      if (M.customize && !opts.includes(M.customize))
        M.customize = opts[0]

      let color = obj[M.customize]
      return <div>{!M.customize ?
        <div class='hidden collapse'>
          <p><a class='bar' href='#'
            onpointerdown={e => {
              M.customize = 'color'
              onchange('merge')
            }}>Show all colors</a></p></div> :

        <div class='shown collapse'>
          <div class='toolbar'>
            <p>{title}</p>
            <div class='wide' columns={opts.length}>
            {
              opts.map(opt => <div class={'opt' + (M.customize === opt ? ' sel' : '')}
                back={obj[opt]} onpointerdown={e => {
                  M.customize = opt
                  onchange('merge')
                }} />)
            }
            </div>
            <div id='picker' oncreate={v => {
              M.picker = new iro.ColorPicker(v.dom, {width: 234, sliderSize: 16,
                handleSvg: '#iroCustomHandle', margin: 6, color, layout: [
                {component: iro.ui.Box},
                {component: iro.ui.Slider, options: {sliderType: 'hue'}},
                {component: iro.ui.Slider, options: {sliderType: 'alpha'}}
              ]})
              M.picker.on('color:change', ch => {
                let hex = ch.hex8String.toUpperCase()
                onchange('merge', {[M.customize]: hex})
              })
            }} onupdate={v => (M.picker.color.hex8String.toUpperCase() !== color && M.picker.color.set(color))} />
            <div class='hex-display'>
              <p class='value'><input type="text" value={color || ''} onchange={e => onchange('merge', {[M.customize]: e.target.value})} /></p>
              <p class='fmt'>HEX</p>
            </div>
          </div>
          <p><a class='bar' href='#' onpointerdown={e => {
            M.customize = null
            onchange('merge')
          }}>Hide all colors</a></p>
        </div>}
      </div>
    },

    solid: (meta, obj, key, title, onchange) => {
      return <div>
        <div class='toolbar'>
          <p>{title}</p>
          <div class='palette'>
          {
            M.colors.map(color => <div back={color.code}
              class={'opt' + (obj[key] === color.code ? ' sel' : '')}
              onpointerover={e => onchange('hover', {[key]: color.code})}
              onpointerout={e => onchange('hover')}
              onpointerdown={e => onchange('merge', {[key]: color.code})}
            />)
          }
          </div>
        </div>
        {M.toolbar.picker(meta, obj, 'Customize the color', [key], onchange)}
      </div>
    },

    gradient: (meta, obj, title, onchange) => {
      return <div>
        <div class='toolbar'>
          <p>{title}</p>
          <div class='wide'>
          {
            M.directions.map(d => <div class={'opt' + (obj.direction === d ? ' sel' : '')}
              onpointerover={e => onchange('hover', {direction: d})}
              onpointerout={e => onchange('hover')}
              onpointerdown={e => onchange('merge', {direction: d})}>
                <img src={icons[`arrow_${d}`]} /></div>)
          }
          </div>
          <p>Recent gradients</p>
          <div class='palette'>
          {
            M.gradients.map(g => <div class={'opt' + (obj.color === g.color && obj.back === g.back ? ' sel' : '')}
              pattern={`linear-gradient(0deg, ${g.color} 0%, ${g.back} 100%)`}
              onpointerover={e => {onchange('hover', {color: g.color, back: g.back})}}
              onpointerout={e => {onchange('hover')}}
              onpointerdown={e => onchange('merge', {color: g.color, back: g.back})} />)
          }
          </div>
        </div>
        {M.toolbar.picker(meta, obj, 'Customize a gradient', ['color', 'back'], onchange)}
      </div>
    },

    pattern: (meta, obj, onchange) => {
      return <div>
        <div class='toolbar'>
          <p>Patterns</p>
          <div class='palette'>
          {
            M.patterns.map(p => <div class={'opt' + (obj.path === p.path ? ' sel' : '')}
              pattern={'url(' + patterns[p.path] + ')'}
              onpointerover={e => onchange('hover', {path: p.path})}
              onpointerout={e => onchange('hover')}
              onpointerdown={e => onchange('merge', {path: p.path})} />)
          }
          </div>
          <p>Recent color combos</p>
          <div class='palette'>
          {
            M.combos.map(c => <div
              class={'opt' + (obj.color === c.color && obj.back === c.back ? ' sel' : '')}
              pattern={`linear-gradient(135deg, ${c.color} 50%, ${c.back} 50%)`}
              onpointerover={e => {onchange('hover', {color: c.color, back: c.back})}}
              onpointerout={e => {onchange('hover')}}
              onpointerdown={e => onchange('merge', {color: c.color, back: c.back})} />)
          }
          </div>
        </div>
        {M.toolbar.picker(meta, obj, 'Customize pattern colors', ['color', 'back'], onchange)}
      </div>
    }
  }
}

//
// multiverse dom functions
//
// this is a wrapper around mithril.js that lets me simplify the attributes -
// to get stuff like `width` and `height` to be main properties on each
// element - so they can get saved as JSON. (Rather than needing to set
// obj.props.style.top = '100px', you can do obj.props.top = 100.) And the
// library will translate to DOM, SVG, text/html and so on.
//

//
// Make simple HTML-like object.
//
function h(type, attrs, ...children) {
  children = children.flat()
  if (attrs) {
    if (attrs.href === '#')
      attrs.href = 'javascript:;'
    applyCss(attrs)
  }
  return m(type, attrs, ...children)
}

//
// Sync properties between a value and the actual DOM object's (or other
// object's) properties.
//
function applyStyle (attrs, styleName, prop, tmpl1 = '', tmpl2 = '') {
  let val = attrs[prop]
  if (val === null || typeof(val) === 'undefined')
    return

  if (tmpl2)
    val = tmpl1 + val + tmpl2
  else
    val += tmpl1

  if (!attrs.style)
    attrs.style = {}
  if (typeof(styleName) === 'string')
    attrs.style[styleName] = val
  else
    for (let name of styleName) {
      attrs.style[name] = val
    }
  delete attrs[prop]
}

//
// Apply a variety of CSS and HTML attributes.
//
function applyCss(props) {
  applyStyle(props, 'left', 'x', 'px')
  applyStyle(props, 'top', 'y', 'px')
  applyStyle(props, 'width', 'width', 'px')
  applyStyle(props, 'min-height', 'minHeight', 'px')
  applyStyle(props, 'height', 'height', 'px')
  applyStyle(props, 'font-family', 'font', '"', '"')
  applyStyle(props, 'font-size', 'size')
  applyStyle(props, 'line-height', 'line')
  applyStyle(props, 'color', 'fore')
  applyStyle(props, 'filter', 'filter')
  applyStyle(props, 'background-color', 'back')
  applyStyle(props, 'background-image', 'pattern')
  applyStyle(props, 'grid-template-columns', 'columns', 'repeat(', ', 1fr)')
  applyStyle(props, ['mask-image', '-webkit-mask-image'], 'mask')
  applyStyle(props, 'display', 'display')
  applyStyle(props, 'overflow', 'overflow')
  applyStyle(props, 'box-shadow', 'shadow')
  applyStyle(props, 'border-width', 'border', 'px')
  applyStyle(props, 'border-radius', 'radius', 'px')
  applyStyle(props, 'border-style', 'borderStyle')
  applyStyle(props, 'border-color', 'stroke')
}

//
// Box HTML builder
//
function bgStyle (fill, border) {
  if (fill) {
    let s = {radius: border.radius}
    switch (fill.type) {
      case 'Gradient': {
        let deg = 0
        switch (fill.direction) {
          case 'horizontal':     deg = 90;  break
          case 'diagonal_left':  deg = 315; break
          case 'diagonal_right': deg = 45;  break
        }
        return {pattern: `linear-gradient(${deg}deg, ${fill.color || "#FFFFFF"} 0%, ${fill.back || "transparent"} 100%)`, ...s}
      }
      case 'Pattern':
        return {mask: 'url(' + patterns[fill.path || 'confetti'] + ')',
            back: fill.color || "#FFFFFF", ...s}
        break
      case 'None':
        return {back: 'transparent'}
    }
    return {back: fill.color || "#FFFFFF", ...s}
  }
}

function shadowBlur(style) {
  if (style && style !== 'plain') {
    return style.replace('_', '(') + ')'
  }
  return null
}

function box (cls, b, contents, layout = null) {
  let contentStyle = {}
  if (b.border.style !== 'none') {
    let bp = b.border.style.split('_')
    contentStyle = {borderStyle: bp[0], border: Number(bp[1].replace('px', '')),
      radius: b.border.radius}
  }

  let cls1 = cls.split(' ')[0]
  return M.h('div', {class: cls},
    M.h('div', {class: cls1 + '-shadow', back: (b.shadow.type === 'Pattern' ? b.shadow.back : null), radius: b.border.radius,
      filter: shadowBlur(b.shadow.style)},
      M.h('div', bgStyle(b.shadow, b.border))),
    M.h('div', {class: cls1 + '-highlight', back: (b.highlight?.type === 'Pattern' ? b.highlight?.back : null), radius: b.border.radius,
      filter: shadowBlur(b.highlight?.style)},
      M.h('div', bgStyle(b.highlight, b.border))),
    M.h('div', {class: cls1 + '-fill', back: (b.fill.type === 'Pattern' ? b.fill.back : null), radius: b.border.radius},
      M.h('div', bgStyle(b.fill, b.border))),
    M.h('div', {class: cls1 + '-content' + (layout !== null ? ' mv_l' + layout : ''),
      stroke: b.border.color, ...contentStyle}, contents))
}

function fontStyle (fill) {
  switch (fill.type) {
    case 'Solid':
      return {fore: fill.color}
    case 'Gradient': {
      let deg = 0
      switch (fill.direction) {
        case 'horizontal':     deg = 90;  break
        case 'diagonal_left':  deg = 315; break
        case 'diagonal_right': deg = 45;  break
      }
      return {pattern: `linear-gradient(${deg}deg, ${fill.color || "#FFFFFF"} 0%, ${fill.back || "transparent"} 100%)`}
    }
    case 'Pattern':
      return {mask: 'url(' + patterns[fill.path || 'confetti'] + ')',
          back: fill.color || "#FFFFFF"}
      break
  }
  return {fore: 'transparent'}
}

let fontDetails = {}
for (let f of M.fonts) {
  fontDetails[f.name] = f
}

function text (ht, opts, contents, force = null) {
  let reg = force || fontDetails[opts.font.family]?.regular
  let obj = Object.assign(fontStyle(opts.fill), {font: opts.font.family, height: opts.height, minHeight: opts.minHeight,
    "font-style": opts.font.style, line: reg?.line, size: reg?.size, class: 'content ' + (ht === 'div' ? 'box-block' : 'title-block')})
  return M.h(ht, obj, contents)
}

function colorToValue(c) {
  let val = 0
  for (let i = 0; i < 3; i++) {
    val += parseInt(c.substr((i * 2) + 1, 2), 16)
  }
  if (c.length > 7) {
    val += (765 - val) * ((255 - parseInt(c.substr(7, 2), 16)) / 255.0)
  }
  return val
}

function calcHeader (def) {
  let val = 230, sval = 150
  let color = "#5c32ec", back = "#7443FF90"
  for (let section of [def.main, def.title]) {
    let colors = [section.fill.color, section.fill.back, section.border.color,
      section.shadow.color, section.shadow.back, section.highlight.color,
      section.highlight.back, section.text.fill.color]
    for (let c of colors) {
      if (c) {
        let cval = colorToValue(c)
        let mval = Math.abs(200 - cval)
        if (mval < val) {
          color = c
          val = mval
        }
        mval = Math.abs(640 - cval)
        if (mval < sval) {
          back = c
          sval = mval
        }
      }
    }
  }
  return {color, back}
}

//
// Use highest contrast color from the box frame to build a post title
//
function title (def, post) {
  let colors = calcHeader(def)
  let style = {color: colors.color, "text-shadow": `2px 2px ${colors.back}`, "font-family": def.title.text.font.family}
  let content = []
  if (post.title) {
    content.push(m("h1", post.title))
  }
  if (post.summary) {
    content.push(m("div", {class: "summary", style: {"text-shadow": "0px 0px", "font-family": def.main.text.font.family}}, post.summary))
  }
  return m("div", {class: `header mv_fl${def.layout || ''}`, style}, content)
}

function subh (def, text) {
  let colors = calcHeader(def)
  text.style = {color: colors.color, "font-family": def.main.text.font.family}
  return text
}

function postSummary(post, order_by = 'published_at') {
  let summary = post.summary
  if (post[order_by]) {
    let at = 'Composed '
    if (order_by === 'updated_at') {
      at = 'Last edited '
    }
    at += utils.timeBlur(post[order_by])
    if (summary) {
      summary += ` <span class="at">&bull; ${at}</span>`
    } else {
      summary = `<span class="at">${at}</span>`
    }
  }
  return m.trust(summary)
}

//
// Simple frame creation using a frame definition. (Allows us to display boxes
// with custom content.)
//
function frame(def, author, contents, layout = 1, height = null) {
  let opts = def.main.text
  if (height) {
    opts = Object.assign({height}, def.main.text)
  }
  return M.box(`box mv_fl${def.layout || ''}`, def.main, [
    M.box('title', def.title,
      M.text('h3', def.title.text, [
        (author.avatar && m("img", {src: author.avatar})),
         m("span", {class: "author"}, author.nametag)
      ])),
      M.text('div', opts, contents)], layout)
}

//
// Full generation of posts from frame definition and post content JSON
// layouts.
//
function post(url, def, author, post, props = null) {
  if (def) {
    let i = 0
    let published = post?.published
    let frame = M.frame(def, author, (post?.content?.children || []).map(c => {
      let html, bs = {class: `box-element box-element-${c.tag}`, tabindex: "0",
        key: utils.id(c), x: c.x, y: c.y, radius: c.radius}

      if (c.borderStyle && c.borderStyle !== 'none') {
        let bp = c.borderStyle.split('_')
        bs.borderStyle = bp[0]
        bs.border = Number(bp[1].replace('px', ''))
        bs.stroke = c.stroke
      }

      if (c.shadow && c.shadow !== 'none') {
        let blur = 0
        if (c.shadow !== 'plain') {
          blur = Number(c.shadow.match(/^blur_(\d+)/)[1])
        }
        bs["shadow"] = `4px 4px ${blur}px ${c.shadowColor}`
      }

      switch (c.tag) {
        case "img":
          let onload = function (e) {
            if (!c.width || c.width > 700) {
              c.width = Math.min(e.target.offsetWidth, 700)
              if (c.x && (c.x + c.width) > 700) {
                c.x = 700 - c.width
              }
            }
            if (post.images && !(c.src instanceof File)) {
              post.images[c.src] = true
            }
            m.redraw()
          }
          html = M.h('img', {width: c.width, height: c.height, onload, crossOrigin: "anonymous",
            src: (c.src instanceof File ? c.src.objectURL :
              (published ? `/user/${url.split('/').join('/posts/')}/${c.src}` :
                `${process.env.MV_API}uploads/${api.token}/${author.username}/posts/${post.id}/${c.src}`))})
        break
        case "html":
          bs.width = c.width
          bs.minHeight = c.height
          bs.fore = c.fore
          bs.back = c.back
          bs.font = c.font
          let reg = fontDetails[c.font]?.regular
          if (reg) {
            bs.size = reg.size
            bs.line = reg.line
          }
          if (!props) {
            html = m.trust(c.text)
          }
        break
      }
      if (props?.oncreate) {
        bs.oncreate = v => props.oncreate(v, c)
      }
      if (props?.onbeforeremove) {
        bs.onbeforeremove = props.onbeforeremove
      }
      i += 1
      c.vnode = M.h('div', bs, html)
      return c.vnode
    }), post?.content?.layout, post?.content?.height)
    return frame
  }
}

function link(href, titl) {
  return <li>{m(m.route.Link, {href, class: `mvt_link ${href === window.location.pathname && 'active'}`}, titl)}</li>
}

export default Object.assign(M, {h, applyStyle, box, frame, post, text,
  calcHeader, subh, title, link, postSummary})
