import M from './multiverse'
import m from 'mithril'
import api from './api'
import swarm from 'webrtc-swarm'
import signalhub from 'signalhub'

var state = {}
var GREETINGS = ['haiyo', 'HIIIIPE', ':levitating man:', 'yap', 'sweeeeet']

export default {
  oninit: function () {
    var hub = signalhub('multiverse', ['https://s.multiverse.click'])
    var sw = swarm(hub)
    this.cursors = {}
    this.peerIds = []
    this.frames = []
    let self = this
    api.title("Live")
    sw.on('peer', function (peer, id) {
      self.peerIds.push(id)
      peer.on('data', function (data) {
        let msg = JSON.parse(data.toString())
        let obj = msg[1]
        if (!obj) return

        switch (msg[0]) {
          case 'cursor':
            self.cursors[id] = {position: 'absolute',
              top: `${obj.y}px`, left: `${obj.x}px`}
          break
          case 'frame':
            self.frames.push(obj)
          break
        }
        m.redraw()
      })
      console.log('connected to a new peer:', id)
      console.log('total peers:', sw.peers.length)
    })
    sw.on('disconnect', function (peer, id) {
      self.peerIds = self.peerIds.filter(peerId => peerId != id)
      console.log('disconnected from a peer:', id)
      console.log('total peers:', sw.peers.length)
    })
    document.addEventListener('mousemove', e => {
      sw.peers.map(peer => peer.send(JSON.stringify(['cursor', {x: e.pageX, y: e.pageY}])))
    })
    document.addEventListener('click', e => {
      let obj = {def: state.frames[0].definition, text: "haiyo", style: `position: absolute; top: ${e.pageY}px; left: ${e.pageX}px;`,
        avatar: api.avatar(), nametag: api.nametag()}
      console.log(obj)
      self.frames.push(obj)
      sw.peers.map(peer => peer.send(JSON.stringify(['frame', obj])))
      m.redraw()
    })
    state.frames = []
    api.getFrames().
      then(obj => state.frames = obj.frames || [])
  },

  view: function() {
    return <div id="live">
      <div class="cursors">
        {this.peerIds.map(peerId => m("div", {style: this.cursors[peerId]}, "^*^"))}
      </div>
      <div class="boxes">
        {this.frames.map(f => m("div", {style: f.style},
          M.box('box', f.def.main, [
            M.box('title', f.def.title,
              M.text('h3', f.def.title.text, [
                (f.avatar && m("img", {src: f.avatar})),
                 m("span", {class: "author"}, f.nametag)
              ])),
              M.text('div', f.def.main.text, GREETINGS[Math.floor(Math.random() * GREETINGS.length)])]))
        )}
      </div>
    </div>
  }
}
