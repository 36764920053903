export default {
  glitchyowl: {
    username: 'glitchyowl',
    nametag: '.·: glitchyowl ·:☽✧·',
    avatar: 'https://multiverse.plus/user/glitchyowl/avatar.jpg',
    frame: {
      main: {
        fill: {
          type: "Gradient",
          direction: "diagonal_right",
          color: "#EDEDFF00",
          back: "#D1EAF0"
        },
        border: {
          color: "#3E0EFF",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#B8A6FE"
        },
        highlight: {
          style: "plain",
          type: "None"
        },
        text: {
          font: {
            family: "Contrail One"
          },
          fill: {
            type: "Solid",
            color: "#3111B1"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#D1E0F4"
        },
        border: {
          color: "#3E0EFF",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#B8A6FE"
        },
        highlight: {
          style: "plain",
          type: "None"
        },
        text: {
          font: {
            family: "Contrail One"
          },
          fill: {
            type: "Solid",
            color: "#3400FF"
          }
        }
      }
    }
  },

  kicks: {username: 'kicks',
    nametag: 'Kicks Condor',
    avatar: 'https://multiverse.plus/user/kicks/avatar.png',
    frame: {
      "main": {
        "fill": {
          "color": "#F1EFE4"
        },
        "border": {
          "color": "#000000",
          "style": "solid_1px",
          "radius": 0
        },
        "shadow": {
          "style": "blur_4px",
          "type": "Solid",
          "color": "#EC65C7"
        },
        "highlight": {
          "style": "blur_4px",
          "type": "Solid",
          "color": "#99F6F7"
        },
        "text": {
          "font": {
            "family": "Maitree"
          },
          "fill": {
            "type": "Solid",
            "color": "#000000"
          }
        }
      },
      "title": {
        "fill": {
          "type": "Solid",
          "color": "#FFFFFF"
        },
        "border": {
          "color": "#000000",
          "style": "solid_1px",
          "radius": 0
        },
        "shadow": {
          "style": "blur_4px",
          "type": "Solid",
          "color": "#EC65C7"
        },
        "highlight": {
          "style": "blur_4px",
          "type": "Solid",
          "color": "#99F6F7"
        },
        "text": {
          "font": {
            "family": "Times New Roman"
          },
          "fill": {
            "type": "Solid",
            "color": "#000000"
          }
        }
      }
    }
  }
}
