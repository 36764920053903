import api from './api'
import m from 'mithril'
import u from 'umbrellajs'
import M from './multiverse'

import frame from './box'
import profile from './profile'
import compose from './composer'
import welcome from './welcome'
import live from './live'
import groupView from './groupView'
import postView from './postView'
import images from './images'
import tasks from './tasks'

// TODO: get code splitting working.
// const frame = import('./box')
// const compose = import('./composer')
// const welcome = import('./welcome')

//
// Window 'onload' function.
// Also a convenient loader for some basic HTML.
//
var SignIn = {
  dispatch: function(e) {
    SignIn.error = null
    api.post("signin", {email: document.getElementById("signin_email").value}).
      then(() => m.route.set("/profile/signin")).
      catch(e => SignIn.error = "Couldn't use the login. Are you sure this email has an account?")
  },
  view: function(vnode) {
    api.title("Login")
    return m("div", {id: "form"},
      m("div", {class: "main"}, [
        m("h2", "Log into Multiverse~*"),
        m("p", ["If you don’t have an account yet, ",
          m(m.route.Link, {class: "icon", href: "/profile/signup"}, "click here"),
          " to create an account."]),
        m("label", {for: "signin_email"}, "Email"),
        m("input", {type: "email", name: "email", id: "signin_email", class: "single",
          placeholder: "heyo@multiverse.plus"}),
        m("button", {onclick: this.dispatch}, "Login by email"),
        this.error && m("p", this.error)
      ]))
  }
}

var SignUp = {
  dispatch: function(e) {
    SignUp.error = null
    api.post('signup', {user: {email: document.getElementById("signup_email").value}}).
      then(() => m.route.set("/profile/signin")).
      catch(e => SignUp.error = e.details)
  },
  view: function(vnode) {
    api.title("Create an account")
    return m("div", {id: "form"},
      m("div", {class: "main"}, [
        m("h2", "Join Multiverse~*"),
        m("label", {for: "signup_email"}, "Email"),
        m("input", {type: "email", name: "email", id: "signup_email", class: "single",
          placeholder: "heyo@multiverse.plus"}),
        m("button", {onclick: this.dispatch}, "Sign me up"),
        this.error && m("p", this.error)
      ]))
  }
}

var SignInCode = {
  dispatch: function(e) {
    SignInNonce({nonce: document.getElementById("signin_code").value})
  },
  view: function(vnode) {
    api.title("Login with a code")
    return m("div", {id: "form"},
      m("div", {class: "main"}, [
        m("p", "Okie! You've been sent a temporary login code. Look for an e-mail from us."),
        m("input", {type: "email", name: "email", id: "signin_code", class: "single",
          placeholder: "Paste login code", value: ""}),
        m("button", {onclick: this.dispatch}, "Login with code")
      ]))
  }
}

var SignInNonce = attrs => {
  api.get("signin/" + attrs.nonce).
  then(obj => {
    api.setToken(obj.token, true)
    m.route.set("/profile")
  }).catch(() => m.route.set("/signin"))
  return []
}

var Unsub = {
  oninit: function () {
    let uuid = m.route.param("uuid")
    api.unsubscribe(uuid).
      then(() => this.message = "You are unsubscribed. Proper. :sunglasses:")
  },
  view: function(vnode) {
    api.title("Settings")
    return m("div", {id: "settings"},
      m("p", this.message))
  }
}

var Auth = page => {
  return {onmatch: () =>
    api.profile ? (api.profile?.username ? page : m.route.set("/welcome")) : m.route.set("/signin") }
}

//
// Allows us to view posts without leaving the app.
//
var ServerLoad = {
  oninit: function (args) {
    this.content = []
    let key = args.attrs.key
    if (key === 'home') {
      key = 'en/home'
    }

    fetch(`/${key}`, {cache: 'no-cache'}).
      then(resp => resp.text()).
      then(async str => {
        let parser = new DOMParser()
        let html = parser.parseFromString(str, 'text/html')
        let node = html.getElementById("app")
        let app = document.getElementById("app")
        if (node.children[0].id === "post_404") {
          //
          // View private posts
          //
          let parts = key.split('/')
          let post = await api.get(`posts/u/${parts[0]}/u/${parts[1]}`, api.token)
          post.author = api.author(post.author)
          post.summary = M.postSummary(post)
          let frame = await api.getFrame(post.frame_id)
          this.content = m("div", {class: "post"}, [M.title(frame.definition, post),
            m("div", {class: "post-body"}, [
              M.post(post.url, frame.definition, api.author(), post)
            ]),
            m("script", {id: "post-meta", type: "application/json"}, JSON.stringify(post))
          ])
          m.redraw()
        } else {
          //
          // View public posts
          //
          let styles = html.querySelectorAll("link[rel='stylesheet']")
          app.replaceChildren(...node.childNodes)
          for (let ss of styles) {
            if (ss.href.includes("/mv_")) {
              let css = document.createElement("style")
              css.setAttribute("type", "text/css")
              css.innerText = `@import "${ss.href}";`
              app.appendChild(css)
            }
          }
          let meta = html.getElementById("post-meta")
          if (meta) {
            app.appendChild(meta)
          }
        }

        if (u("#group").length > 0) {
          groupView()
        } else {
          postView()
        }
      })
  },
  view: function (vnode) {
    return this.content
  },
  onbeforeremove: () => document.getElementById("app").innerHTML = ""
}

var SvgLoad = {
  oninit: function (args) {
    let parts = args.attrs.key.split('/')
    api.get(`posts/u/${parts[0]}/u/${parts[1]}`, api.token).
      then(obj => {
        obj.author = api.author(obj.author)
        api.getFrame(obj.frame_id).
          then(frame => tasks.generateSvg(obj, frame.definition).then(data =>
            u("#app").append(u("<img>").attr({src: data}))))
          // u("#app").append(u("<object>").attr({type: 'image/svg+xml', data}))))
      })
  },
  view: v => [],
  onbeforeremove: () => document.getElementById("app").innerHTML = ""
}

api.ready(() => {
  m.route.prefix = ""
  m.route(document.getElementById("app"), "/compose", {
    "/profile": Auth(profile),
    "/profile/posts/:key": Auth(profile),
    "/profile/svg/:key...": SvgLoad,
    "/compose": Auth(compose),
    "/compose/:key...": Auth(compose),
    "/welcome": {onmatch: () => api.profile ? welcome : m.route.set("/signin")},
    "/settings/unsubscribe/:uuid": Unsub,
    "/frame": Auth(frame),
    "/frame/:key": Auth(frame),
    "/signin/:nonce": {onmatch: SignInNonce},
    "/signin": SignIn,
    "/profile/signin": SignInCode,
    "/profile/signup": SignUp,
    "/live": live,
    "/app/images": images,
    "/:key...": ServerLoad
  })
})
