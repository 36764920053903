import m from 'mithril'
import utils from './utils'

function readBlob(blob, fn) {
  let reader = new FileReader()
  reader.onload = function (e) {
    fn(e.target.result)
  }
  reader.readAsDataURL(blob)
}

function imgblat(files, opts, fn) {
  let i = 0 
  let allow = ['png', 'jpeg', 'gif', 'bmp', 'webp']
  for (let src of files) {
    try {
      let ext = null

      //
      // Detect and possibly correct the file extension
      //
      if (src.name) {
        ext = src.name.split(".").pop()
        if (ext) {
          ext = ext.toLowerCase()
        }
        if (ext === 'jpg') { /* fix up for the mime type */
          ext = 'jpeg'
        }
      }
      if (!allow.includes(ext)) {
        ext = (src.type || "").split("/").pop()
        if (!allow.includes(ext)) {
          throw new Exception("Image file format couldn't be detected")
        }
        src.name += "." + ext
      }

      if (src.size) {
        let callback = function (s, d) {
          s.objectURL = d
          fn({src: s})
        }
        readBlob(src, data => {
          if (ext === 'gif' || ext === 'webp') {
            callback(src, data)
          } else {
            utils.measure(m("img", {src: data})).then(siz => {
              let scale = Math.min((opts.maxWidth ? opts.maxWidth / siz[0] : 1.0), (opts.maxHeight ? opts.maxHeight / siz[1] : 1.0))
              if (scale < 1.0) {
                let elem = document.createElement('canvas')
                elem.width = scale * siz[0]
                elem.height = scale * siz[1]
                let ctx = elem.getContext('2d')
                ctx.drawImage(siz[2], 0, 0, elem.width, elem.height)
                elem.toBlob(blob => {
                  src = new File([blob], src.name, {type: 'image/' + ext, lastModified: Date.now()})
                  callback(src, elem.toDataURL('image/' + ext))
                }, 'image/' + ext)
              } else {
                if (!(src instanceof File)) {
                  src = new File([src], src.name, {type: 'image/' + ext, lastModified: Date.now()})
                }
                callback(src, data)
              }
            })
          }
        })
      }
    } catch (error) {
      console.log("Error during image upload: ", error)
    }
  }
}

imgblat.readBlob = readBlob

export default imgblat
