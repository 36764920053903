const debounce = (fn, wait, options = {}) => {
	if (!Number.isFinite(wait)) {
		throw new TypeError('Expected `wait` to be a finite number')
	}

	let leadingValue
	let currentPromise
	let timer
	let resolveList = []

	let d = function (...arguments_) {
		return new Promise(resolve => {
			const runImmediately = d.leading && !timer

			clearTimeout(timer)

			timer = setTimeout(() => {
				timer = null

				let result = leadingValue
        if (!d.leading) {
          result = currentPromise = fn.apply(this, arguments_)
        }

				for (resolve of resolveList) {
					resolve(result)
				}

				resolveList = []
			}, wait)

			if (runImmediately) {
				leadingValue = currentPromise = fn.apply(this, arguments_)
				resolve(leadingValue)
			} else {
				resolveList.push(resolve)
			}
		})
	}
  d.leading = options.leading
  //
  // Run the debounced function once, using the existing
  // in-progress call if it's around.
  //
	d.ensure = async (...arguments_) => {
		if (currentPromise) {
			return currentPromise
		}

		try {
			currentPromise = fn.apply(this, arguments_)
			return await currentPromise
		} finally {
			currentPromise = undefined
		}
	}
  //
  // Run the debounced function now, cancelling any timers.
  //
  d.now = async (...arguments_) => {
		clearTimeout(timer)

		try {
			currentPromise = fn.apply(this, arguments_)
			return await currentPromise
		} finally {
			currentPromise = undefined
		}
  }
  //
  // Get the currently running promise
  //
  d.promise = () => currentPromise || Promise.resolve(null)
  //
  // Cancel existing timers
  //
  d.cancel = () => clearTimeout(timer)
  return d
}

export default debounce
