import {EditorState, Plugin} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {baseKeymap} from "prosemirror-commands"
import {history} from "prosemirror-history"
import {keymap} from "prosemirror-keymap"
import {Schema, DOMParser, DOMSerializer} from "prosemirror-model"
import {dropCursor} from "prosemirror-dropcursor"
import {gapCursor} from "prosemirror-gapcursor"
import {schema} from "prosemirror-schema-basic"
import {addListNodes} from "prosemirror-schema-list"
import {buildInputRules} from "./prose/inputrules"
import {buildKeymap} from "./prose/keymap"
import placeholder from "./prose/placeholder"
import "prosemirror-view/style/prosemirror.css"

//
// Setup for full-block elements (paragraphs, lists, etc)
//
const blockSchema = new Schema({
  nodes: addListNodes(schema.spec.nodes, "paragraph block*", "block"),
  marks: schema.spec.marks
})

//
// Setup for inline-only (single paragraph tag with inline HTML,
// such as em, strong, etc)
//
const inlineSchema = new Schema({
  nodes: {
    doc: {content: "block+"},
    paragraph: {
      content: "inline*",
      group: "block",
      parseDOM: [{tag: "p"}],
      toDOM() { return ["p", 0] }
    },
    text: {group: "inline"}
  },
  marks: schema.spec.marks
})

class ProseSet {
  constructor(onInput) {
    this.views = []
    this.history = history()
    this.onInput = onInput
  }

  editor(ele, html, inlineOnly, placeholderText) {
    let domNode = document.createElement("div")
    domNode.innerHTML = html
    let sch = inlineOnly ? inlineSchema : blockSchema
    let plugins = [placeholder(placeholderText), keymap(buildKeymap(sch)),
      keymap(baseKeymap), dropCursor(), gapCursor(), this.history]
    if (!inlineOnly) {
      plugins.push(buildInputRules(sch))
    }

    let state = EditorState.create({
      schema: sch,
      doc: DOMParser.fromSchema(sch).parse(domNode),
      plugins
    })

    let view = new EditorView({mount: ele}, {state,
      handleKeyDown: this.onInput, handleDrop: this.onInput, handlePaste: this.onInput
    })
    this.views.push(view)
    view.toHTML = function() {
      return view.dom.querySelector("span.placeholder") ? "" : view.dom.innerHTML
    }
    return view
  }
}

export default function (onInput) {
  return new ProseSet(onInput)
}
