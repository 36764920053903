import M from './multiverse'
import m from 'mithril'
import u from 'umbrellajs'
import api from './api'
import frames from './frames'
import imgblat from './imgblat'
import characters from './characters'

export default {
  page: 0,

  save: function(e, page) {
    this.error = null
    e.preventDefault()
    if (e.target[0].id == "handle" && !e.target[0].value) {
      this.error = "hey - put a handle in there!"
    } else if (e.target[0].id == "new-avatar" && !this.avatar_file) {
      this.page = page
    } else {
      let form = e
      if (page === 3) {
        form = {"user:avatar_file": this.avatar_file.src,
           "user:nametag": api.profile?.nametag}
      }
      api.saveProfile(form).
        then(() => this.page = page).
        catch(obj => this.error = obj.details.replace("username ", "handle "))
    }
  },

  newFile(e) {
    imgblat(e.target.files, {maxWidth: 160, maxHeight: 160}, obj => {
      this.avatar_file = obj
      m.redraw()
    })
  },

  view(vnode) {
    let glitchyowl = characters.glitchyowl, kicks = characters.kicks
    api.title("Welcome to Multiverse")
    switch (this.page) {
      case 0:
      return <div id="welcome">
        <div class="post">
          {M.title(glitchyowl.frame, {title: "Ayy, welcome to Multiverse!"})}
          {M.frame(glitchyowl.frame, glitchyowl,
            "Looks like you just set foot here.")}
        </div>
        <div class="post">
          {M.frame(kicks.frame, kicks,
            "Yeah hi there - good to have you")}
        </div>
        <div class="post">
          {M.frame(glitchyowl.frame, glitchyowl,
            `At Multiverse, your voice is stylized so that your personality
             shines through rather than getting flattened out. Let's walk
             through a few steps to get you set up~`)}
        </div>
        <div class='prev'>
          <a class="button" href='#' onclick={() => this.page = 1}><span>Next</span></a>
        </div>
      </div>

      case 1:
      return <div id="welcome">
        <form onsubmit={e => this.save(e, 2)}>
          <div class="post">
            {M.title(glitchyowl.frame, {title: "Choose Your Handle"})}
            {M.frame(glitchyowl.frame, glitchyowl,
              "Your handle will be how people can find you in the multiverse.")}
          </div>
          <div class="post">
            {M.frame(kicks.frame, kicks,
              `For stuff like your personal URL - which will be at
               multiverse.plus/youyouyou`)}
          </div>
          <div class="post">
            {M.frame(glitchyowl.frame, glitchyowl,
              `Your name tag will be the name people see`)}
          </div>
          <div class="post">
            {M.frame(kicks.frame, kicks,
              `Why not throw some moons in there ☽☽☽`)}
          </div>
          <div class="fields">
            <div class="field">
              <label for="handle">Handle</label>
              <input class="single" type="text" id="handle" name="user:username" autocorrect="off" autocapitalize="none"
                placeholder="youyouyou" />
            </div>

            <div class="field">
              <label for="nametag">Name Tag</label>
              <input class="single" type="text" id="nametag" name="user:nametag" autocorrect="off" autocapitalize="none"
                placeholder="..`: multiverse:.*" />
            </div>
            {this.error && m("p", this.error)}
          </div>
          <button class="standard">Next</button>
        </form>
      </div>

      case 2:
      return <div id="welcome">
        <form onsubmit={e => this.save(e, 3)}>
          <div class="post">
            {M.title(glitchyowl.frame, {title: "A Pic?"})}
            {M.frame(glitchyowl.frame, glitchyowl,
              "Brighten up your page with your face, or something that represents you!")}
          </div>
          <div class="avatar">
            <a href="#" onclick={() => u("#new-avatar").first().click()}>
            {this.avatar_file ? 
              <img src={this.avatar_file.src.objectURL} /> :
              m("svg", {class: "mvt", width: 96, height: 96, viewBox: "0 0 195 194", xmlns: "http://www.w3.org/2000/svg"}, [
                m("rect", {x: "1.34375", y: "0.612305", width: "192.611", height: "192.611", rx: "1.5"}),
                m("path", {d: "M94.0534 1.56079C82.6236 33.214 62.2257 97.3997 72.0729 100.917C84.3818 105.313 126.586 90.3657 121.31 110.589C117.09 126.767 101.38 147.224 94.0534 155.431"}),
                m("rect", {x: "29.9844", y: "48.3931", width: "9.22519", height: "25.5605", rx: "3", fill: "currentColor"}),
                m("rect", {x: "130.062", y: "48.3931", width: "9.22519", height: "25.5605", rx: "3", fill: "currentColor"})
              ])}
              <br />
              Click to change
            </a>
            <input type="file" accept="image/*" name="avatar_file" id="new-avatar"
              onchange={e => this.newFile(e)}
              style="display:none" />
            {this.error && m("p", this.error)}
          </div>
          <button class="standard">Next</button>
        </form>
      </div>

      case 3:
      return m.route.set("/frame")
    }
  }
}
