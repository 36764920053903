export default [{
    name: 'Juiced Veil',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "horizontal",
          color: "#BBAAFFFF",
          back: "#FFAADD"
        },
        border: {
          color: "#6730B7",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#CBC7F1"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Droid Sans"
          },
          fill: {
            type: "Solid",
            color: "#2A0084FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#EBC7F1FF"
        },
        border: {
          color: "#5F30E6FF",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#D694E7FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Arial"
          },
          fill: {
            type: "Solid",
            color: "#300F5AFF"
          }
        }
      }
    }
  },

  {
    name: 'Ancient Graph',
    definition: {
      main: {
        fill: {
          type: "Pattern",
          direction: "horizontal",
          color: "#F1D1B3FF",
          back: "#FCF2DFFF",
          path: "hatch"
        },
        border: {
          color: "#D9864A",
          style: "solid_2px",
          radius: 5
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#DF95A7"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Times New Roman"
          },
          fill: {
            type: "Solid",
            color: "#410000FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#FFF0E8FF"
        },
        border: {
          color: "#CB564A",
          style: "solid_1px",
          radius: 2
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#D694E7FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Times New Roman"
          },
          fill: {
            type: "Solid",
            color: "#500A00FF"
          }
        }
      }
    }
  },

  {
    name: 'Sneaky Wiki',
    definition: {main:{fill:{type:"Solid",color:"#FFFFEEFF",back:"#FFFFEEFF",path:"polka",direction:"vertical"},border:{color:"#5555FFFF",style:"solid_1px",radius:0},shadow:{type:"Solid",color:"#5555FFFF",back:"#52DDFBFF",direction:"diagonal_right",style:"plain"},highlight:{type:"Solid",color:"#55FFAAFF",style:"plain"},text:{font:{family:"Arya"},fill:{type:"Solid",color:"#5555FFFF"}}},title:{fill:{type:"Solid",color:"#FF5555FF",back:"#EC61A4FF",path:"scales2"},border:{color:"#5555FFFF",style:"solid_1px",radius:0},shadow:{type:"Solid",color:"#5555FFFF",style:"plain"},highlight:{type:"Solid",color:"#55FFAAFF",style:"plain"},text:{font:{family:"Concert One"},fill:{type:"Solid",color:"#FAFAFAFF"}}}}
  },

  {
    name: 'Glamourous',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "horizontal",
          color: "#E741B9",
          back: "#FEB553",
          path: "hatch"
        },
        border: {
          color: "#D9864A",
          style: "solid_2px",
          radius: 5
        },
        shadow: {
          style: "blur_8px",
          type: "Solid",
          color: "#FCADCBFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Corsiva"
          },
          fill: {
            type: "Solid",
            color: "#410000FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#F89EB9FF",
          back: "#FEB553"
        },
        border: {
          color: "#CB564A",
          style: "solid_1px",
          radius: 2
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#D9864A"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Corsiva"
          },
          fill: {
            type: "Solid",
            color: "#500A00FF"
          }
        }
      }
    }
  },

  {
    name: 'Forbidden',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "vertical",
          color: "#140410FF",
          back: "#3B092AFF",
          path: "hatch"
        },
        border: {
          color: "#F0005BFF",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "blur_1px",
          type: "Solid",
          color: "#DD5787FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Red Rose"
          },
          fill: {
            type: "Solid",
            color: "#C5179FFF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#21002DFF",
          back: "#FEB553"
        },
        border: {
          color: "#640249FF",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "blur_1px",
          type: "Solid",
          color: "#DD3977"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Red Rose"
          },
          fill: {
            type: "Solid",
            color: "#D60080FF"
          }
        }
      }
    }
  },

  {
    name: 'Work Order',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "vertical",
          color: "#FF5C00FF",
          back: "#FFD913FF",
          path: "hatch"
        },
        border: {
          color: "#201C1B",
          style: "solid_2px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#FFB884FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Roboto Slab"
          },
          fill: {
            type: "Solid",
            color: "#12010EFF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#FFC100FF",
          back: "#FEB553"
        },
        border: {
          color: "#640249FF",
          style: "solid_2px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#B42222FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Roboto Slab"
          },
          fill: {
            type: "Solid",
            color: "#3A231B"
          }
        }
      }
    }
  },

  {
    name: 'Cool Folder',
    definition:
      {main:{fill:{type:"Solid",color:"#F1ECE9FF",back:"#4FD5C5",path:"polka",direction:"vertical"},border:{color:"#FBFBFBFF",style:"solid_6px",radius:0},shadow:{type:"Solid",color:"#B2C5C1FF",style:"blur_4px"},highlight:{type:"None",style:"plain"},text:{font:{family:"Helvetica Neue"},fill:{type:"Solid",color:"#3B302DFF"}}},title:{fill:{type:"Solid",color:"#FAFAFAFF",back:"#EC61A4FF",path:"scales2",direction:"horizontal"},border:{color:"#F1ECE9FF",style:"solid_6px",radius:13},shadow:{type:"Solid",color:"#DF95A7FF",style:"blur_2px"},highlight:{type:"None",style:"plain"},text:{font:{family:"Helvetica Neue"},fill:{type:"Solid",color:"#2847E0FF"}}}}
  },

  {
    name: 'CGA Modern',
    definition:
      {main:{fill:{type:"Gradient",color:"#F7FCFCFF",back:"#D1EAF0",direction:"vertical"},border:{color:"#42D4D4FF",style:"dotted_1px",radius:0},shadow:{type:"Solid",color:"#77E5E5FF",style:"blur_2px"},highlight:{type:"None",style:"blur_8px"},text:{font:{family:"Oswald"},fill:{type:"Solid",color:"#0093A7FF"}}},title:{fill:{type:"Solid",color:"#FE40D4FF",back:"#EB19A42F",direction:"horizontal"},border:{color:"#1AB69AFF",style:"dotted_1px",radius:5},shadow:{type:"Solid",color:"#B6B5A8FF",style:"blur_1px"},highlight:{type:"Solid",color:"#B6B5A8",style:"blur_4px"},text:{font:{family:"Red Rose"},fill:{type:"Solid",color:"#D4FAFDFF"}}}}
  },

  {
    name: 'Rose Petals',
    definition: {
      main: {
        fill: {
          type: "Pattern",
          direction: "vertical",
          color: "#DD3977",
          back: "#EE4765FF",
          path: "scales2"
        },
        border: {
          color: "#A60E47FF",
          style: "solid_2px",
          radius: 4
        },
        shadow: {
          style: "blur_1px",
          type: "Solid",
          color: "#8B3E3EFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Lora"
          },
          fill: {
            type: "Solid",
            color: "#3D0101FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#4A001BFF",
          back: "#EC61A4FF",
          path: "scales2"
        },
        border: {
          color: "#64021AFF",
          style: "solid_1px",
          radius: 3
        },
        shadow: {
          style: "blur_2px",
          type: "Solid",
          color: "#F872A5FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#CB4A61FF"
          }
        }
      }
    }
  },

  {
    name: 'Clean Cut',
    definition: {
      main: {
        fill: {
          type: "Solid",
          direction: "vertical",
          color: "#F2F2F2FF",
          back: "#4FD5C5",
          path: "polka"
        },
        border: {
          color: "#201C1B",
          style: "dotted_1px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "None",
          color: "#C9AAAAFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#3B302DFF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#FAFAFA",
          back: "#EC61A4FF",
          path: "scales2"
        },
        border: {
          color: "#010000FF",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "blur_2px",
          type: "None",
          color: "#F872A5FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#3A231B"
          }
        }
      }
    }
  },

  {
    name: 'Soft Shine',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "diagonal_left",
          color: "#EBDAAF",
          back: "#00FFFF1A",
          path: "polka"
        },
        border: {
          color: "#F5EEC1",
          style: "solid_2px",
          radius: 2
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#94DBBDFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Montserrat"
          },
          fill: {
            type: "Solid",
            color: "#5C1400FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#FFFAD9C9",
          back: "#00FFFF1A",
          path: "scales2"
        },
        border: {
          color: "#3A231B",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#FEFEFEFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Montserrat"
          },
          fill: {
            type: "Solid",
            color: "#3A231B"
          }
        }
      }
    }
  },

  {
    name: 'Sunrise Marquee',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "diagonal_left",
          color: "#E741B95E",
          back: "#FEB5538C",
          path: "polka"
        },
        border: {
          color: "#F5EEC1",
          style: "dotted_4px",
          radius: 2
        },
        shadow: {
          style: "plain",
          type: "Solid",
          color: "#F4ABCAFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Courier Prime"
          },
          fill: {
            type: "Solid",
            color: "#FFFFFFFF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#FFCCA8FF",
          back: "#00FFFF1A",
          path: "scales2"
        },
        border: {
          color: "#D9864A",
          style: "solid_1px",
          radius: 3
        },
        shadow: {
          style: "blur_1px",
          type: "Solid",
          color: "#94DBBF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Courier Prime"
          },
          fill: {
            type: "Solid",
            color: "#DA755CFF"
          }
        }
      }
    }
  },

  {
    name: 'Fine Vellum',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "diagonal_left",
          color: "#FFEDC0FF",
          back: "#D3FFFF1A",
          path: "polka"
        },
        border: {
          color: "#201C1B",
          style: "solid_1px",
          radius: 2
        },
        shadow: {
          style: "blur_2px",
          type: "Solid",
          color: "#F4ABCAFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#2C2929FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#FFFCFCFF",
          back: "#00FFFF1A",
          path: "scales2"
        },
        border: {
          color: "#201C1B",
          style: "solid_1px",
          radius: 0
        },
        shadow: {
          style: "blur_1px",
          type: "Solid",
          color: "#DB94AEFF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#571B0CFF"
          }
        }
      }
    }
  },

  {
    name: 'New Vellum',
    definition: {
      main: {
        fill: {
          type: "Gradient",
          direction: "diagonal_left",
          color: "#E741B9",
          back: "#FEB553",
          path: "polka"
        },
        border: {
          color: "#201C1B",
          style: "solid_4px",
          radius: 7
        },
        shadow: {
          style: "blur_4px",
          type: "Solid",
          color: "#1AB69A"
        },
        highlight: {
          style: "blur_2px",
          type: "Solid",
          color: "#E1BE51"
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#2C2929FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#F5EEC1",
          back: "#00FFFF1A",
          path: "scales2"
        },
        border: {
          color: "#201C1B",
          style: "solid_2px",
          radius: 5
        },
        shadow: {
          style: "blur_2px",
          type: "None",
          color: "#DB94AEFF"
        },
        highlight: {
          style: "plain",
          type: "None"
        },
        text: {
          font: {
            family: "Spectral"
          },
          fill: {
            type: "Solid",
            color: "#571B0CFF"
          }
        }
      }
    }
  },

  {
    name: 'Diner Tile',
    definition: {
      main: {
        fill: {
          type: "Pattern",
          direction: "diagonal_left",
          color: "#95FFCCFF",
          back: "#D3FFFF1A",
          path: "striped"
        },
        border: {
          color: "#201C1B",
          style: "solid_4px",
          radius: 19
        },
        shadow: {
          style: "blur_2px",
          type: "Solid",
          color: "#E0FFF4FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Montserrat"
          },
          fill: {
            type: "Solid",
            color: "#2C2929FF"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#E9FFF3FF",
          back: "#00FFFF1A",
          path: "scales2"
        },
        border: {
          color: "#130603FF",
          style: "solid_2px",
          radius: 6
        },
        shadow: {
          style: "blur_1px",
          type: "Solid",
          color: "#94DBBF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Montserrat"
          },
          fill: {
            type: "Solid",
            color: "#120704FF"
          }
        }
      }
    }
  },

  {
    name: 'Green Screen',
    definition:
		{
			main: {
				fill: {
					type: "Solid",
					direction: "horizontal",
					color: "#201C1B00",
					back: "#000000FF"
				},
				border: {
					color: "#94FA65FF",
					style: "solid_1px",
					radius: 0
				},
				shadow: {
					style: "plain",
					type: "None",
					color: "#94FA65FF"
				},
				highlight: {
					style: "plain",
					type: "Solid",
					color: "#102E0EFF"
				},
				text: {
					font: {
						family: "Roboto Mono"
					},
					fill: {
						type: "Solid",
						color: "#94FA65FF"
					}
				}
			},
			title: {
				fill: {
					type: "Solid",
					color: "#94FA65FF"
				},
				border: {
					color: "#000000FF",
					style: "solid_1px",
					radius: 0
				},
				shadow: {
					style: "plain",
					type: "None",
					color: "#D694E7FF"
				},
				highlight: {
					style: "plain",
					type: "Solid",
					color: "#95FF777F"
				},
				text: {
					font: {
						family: "Rationale"
					},
					fill: {
						type: "Solid",
						color: "#000000FF"
					}
				}
			}
		}
  },

  {
    name: 'Coffee Cushion',
    definition: {
      main: {
        fill: {
          type: "Pattern",
          direction: "diagonal_left",
          color: "#DD3977",
          back: "#EE7C47",
          path: "polka2"
        },
        border: {
          color: "#534A62FF",
          style: "solid_4px",
          radius: 0
        },
        shadow: {
          style: "blur_2px",
          type: "Solid",
          color: "#F2D99E",
          direction: "horizontal",
          back: "#181414FF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Courier Prime"
          },
          fill: {
            type: "Solid",
            color: "#F2D99E"
          }
        }
      },
      title: {
        fill: {
          type: "Solid",
          color: "#F2D99E",
          back: "#00FFFF1A",
          path: "scales2"
        },
        border: {
          color: "#130603FF",
          style: "solid_2px",
          radius: 0
        },
        shadow: {
          style: "blur_8px",
          type: "None",
          color: "#94DBBF"
        },
        highlight: {
          style: 'plain',
          type: 'None'
        },
        text: {
          font: {
            family: "Montserrat"
          },
          fill: {
            type: "Solid",
            color: "#120704FF"
          }
        }
      }
    }
  },

  {name: "Ocean Mosaic",
   definition: 
    {main:{fill:{type:"Gradient",color:"#EBDAAFF7",back:"#00FFFF3F",path:"polka",direction:"diagonal_left"},border:{color:"#FAFAFAFF",style:"none",radius:2},shadow:{type:"Pattern",color:"#D4C8D8FF",back:"#A6C4C500",path:"weave",style:"plain"},highlight:{type:"None",style:"plain"},text:{font:{family:"Rationale"},fill:{type:"Solid",color:"#5C1400FF"}}},title:{fill:{type:"Solid",color:"#FFFFFFFF",back:"#00FFFF1A",path:"scales2"},border:{color:"#3A231B",style:"none",radius:0},shadow:{type:"None",color:"#FEFEFEFF",style:"plain"},highlight:{type:"None",style:"plain"},text:{font:{family:"Rationale"},fill:{type:"Solid",color:"#3A231B"}}}}
  },

  {name: "Glam Tortoise",
   definition:
    {main:{fill:{type:"Gradient",color:"#CCCC99FF",back:"#D1EAF0",direction:"diagonal_right"},border:{color:"#3E0EFF",style:"solid_1px",radius:0},shadow:{type:"Solid",color:"#003FB7FF",style:"plain"},highlight:{type:"None",style:"plain"},text:{font:{family:"Oswald"},fill:{type:"Solid",color:"#2142E1FF"}}},title:{fill:{type:"Gradient",color:"#415CE7FF",back:"#E891F0FF",direction:"horizontal"},border:{color:"#DD3977FF",style:"solid_1px",radius:5},shadow:{type:"Solid",color:"#003FB7FF",style:"plain"},highlight:{type:"None",style:"plain"},text:{font:{family:"Lobster"},fill:{type:"Solid",color:"#E0D2B7FF"}}}}
  },

  {name: "The Statement",
   definition:
    {main:{fill:{type:"Gradient",color:"#EEEEEEFF",back:"#FFAAD8FF",direction:"vertical"},border:{color:"#000000",style:"solid_2px",radius:0},shadow:{type:"Solid",color:"#201C1BFF",back:"#FFFFFF",style:"plain"},highlight:{type:"None",style:"plain"},text:{font:{family:"Roboto Slab"},fill:{type:"Solid",color:"#000000FF"}}},title:{fill:{type:"Solid",color:"#262626FF",back:"#0E0106",path:"weave2"},border:{color:"#000000",style:"solid_1px",radius:0},shadow:{type:"Solid",color:"#0C192EFF",style:"plain"},highlight:{type:"None",style:"plain"},text:{font:{family:"Red Rose"},fill:{type:"Solid",color:"#C2EFDBFF"}}}}
  },

  {name: "Santa Monica",
   definition:
    {main:{fill:{type:"Pattern",color:"#F1D2ACFF",back:"#D1F1EDFF",path:"confetti",direction:"vertical"},border:{color:"#CB564AFF",style:"solid_1px",radius:8},shadow:{type:"Solid",color:"#C9AAAAFF",style:"plain"},highlight:{type:"Solid",style:"blur_8px"},text:{font:{family:"Georgia"},fill:{type:"Solid",color:"#8B1C4AFF"}}},title:{fill:{type:"Gradient",color:"#FAFAFA00",back:"#FD7880FF",path:"scales2",direction:"horizontal"},border:{color:"#010000FF",style:"none",radius:9},shadow:{type:"None",color:"#F872A5FF",style:"blur_2px"},highlight:{type:"None",style:"plain"},text:{font:{family:"Caveat"},fill:{type:"Solid",color:"#FAFAFAFF"}}}}
  },

  {name: "Punch Card",
   definition:
    {main:{fill:{type:"Pattern",color:"#F7F8DAFF",back:"#D5B77D00",path:"dense",direction:"vertical"},border:{color:"#F7F8DAFF",style:"solid_4px",radius:20},shadow:{type:"None",color:"#FAFAFAFF",back:"#FFFFFF00",path:"striped3",style:"blur_1px"},highlight:{type:"None",color:"#B675C600",style:"blur_4px"},text:{font:{family:"Pacifico"},fill:{type:"Solid",color:"#071121FF"}}},title:{fill:{type:"Solid",color:"#DCD0B0FF",back:"#EC61A4FF",path:"scales2"},border:{color:"#FAFAFAFF",style:"solid_1px",radius:0},shadow:{type:"None",color:"#B675C6FF",style:"blur_2px"},highlight:{type:"None",style:"blur_4px"},text:{font:{family:"Oswald"},fill:{type:"Solid",color:"#2D274FFF"}}}}
  },

  {name: "Drafting Marks",
   definition:
    {main:{fill:{type:"Gradient",color:"#FAFEFFFF",back:"#FFFFFF00",path:"weave",direction:"vertical"},border:{color:"#201C1BFF",style:"solid_1px",radius:0},shadow:{type:"Pattern",color:"#ED4924FF",path:"square2",style:"plain"},highlight:{type:"Gradient",color:"#FAFEFF",back:"#FFFFFFB7",direction:"diagonal_right",style:"plain"},text:{font:{family:"Crimson Text"},fill:{type:"Solid",color:"#201C1BFF"}}},title:{fill:{type:"Gradient",color:"#FAFEFFFF",back:"#FFFFFFA8",path:"scales2",direction:"horizontal"},border:{color:"#201C1BFF",style:"solid_2px",radius:0},shadow:{type:"None",color:"#201C1BFF",style:"plain"},highlight:{type:"Solid",color:"#FAFAFAFF",style:"plain"},text:{font:{family:"Spectral"},fill:{type:"Solid",color:"#201C1BFF"}}}}
  },

  {name: "Enlightenment",
   definition:
    {main:{fill:{type:"Gradient",color:"#201C1BFF",back:"#4722957C",path:"hatch"},border:{color:"#1AB69AFF",style:"solid_2px",radius:0},shadow:{type:"Gradient",color:"#5DFFE3FF",back:"#D55DFFFF",path:"striped",direction:"diagonal_left",style:"plain"},highlight:{type:"Pattern",color:"#09B578FF",back:"#131C2900",path:"striped",style:"plain"},text:{font:{family:"Roboto Mono"},fill:{type:"Solid",color:"#48E5C9FF"}}},title:{fill:{type:"Solid",color:"#21002D00",back:"#FEB553"},border:{color:"#640249FF",style:"none",radius:0},shadow:{type:"Solid",color:"#DD3977",style:"blur_1px"},highlight:{type:"None",style:"plain"},text:{font:{family:"Roboto Mono"},fill:{type:"Solid",color:"#CBC7F1FF"}}}}
  },

  {name: "Leotard!",
   definition:
    {"main":{"fill":{"type":"Gradient","color":"#FAFEFFFF","back":"#FFFFFF00","direction":"vertical"},"border":{"color":"#FAFAFAFF","style":"solid_4px","radius":0},"shadow":{"type":"Gradient","color":"#EFB4E6FF","back":"#00FFB31A","style":"blur_2px"},"highlight":{"type":"None","style":"plain"},"text":{"font":{"family":"Montserrat"},"fill":{"type":"Solid","color":"#CC00DEFF"}}},"title":{"fill":{"type":"Gradient","color":"#91FFC4FF","back":"#B3F9D300","direction":"diagonal_right"},"border":{"color":"#000000","style":"none","radius":0},"shadow":{"type":"Solid","color":"#FAFAFAFF","style":"plain"},"highlight":{"type":"None","style":"plain"},"text":{"font":{"family":"Comic Sans"},"fill":{"type":"Solid","color":"#B400F4FF"}}}}
  }
]
