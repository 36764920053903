import u from 'umbrellajs'
import api from './api'
import tasks from './tasks'
import utils from './utils'

import images from '../images/*.png'
import icons from '../icons/*.svg-inline'

export default function() {
  // 
  // Add copy link to page
  //
  let post = u(".post")
  if (post.length === 1) {
    let getPost = async function () {
      let post = JSON.parse(document.getElementById("post-meta").textContent)
      let frame = await api.postContentAndFrame(post)
      return {post, frame}
    }

    let banner = u('<div class="banner">')
    let share = u(`<a class="button icon" href="javascript:;">
      <span class="base">
        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.79128 12.3965C8.09149 12.3965 8.34882 12.1563 8.34882 11.8561V3.28708L8.30593 2.0948L8.95783 2.77243L10.2788 4.162C10.3817 4.2735 10.5275 4.32497 10.6562 4.32497C10.9478 4.32497 11.1623 4.11911 11.1623 3.83605C11.1623 3.69023 11.1108 3.57872 10.9993 3.46721L8.19442 0.739546C8.05718 0.602305 7.9371 0.55084 7.79128 0.55084C7.65404 0.55084 7.53395 0.602305 7.38813 0.739546L4.58327 3.46721C4.48034 3.57872 4.42029 3.69023 4.42029 3.83605C4.42029 4.11911 4.62616 4.32497 4.91779 4.32497C5.05503 4.32497 5.20943 4.2735 5.30378 4.162L6.62473 2.77243L7.2852 2.0948L7.24231 3.28708V11.8561C7.24231 12.1563 7.49106 12.3965 7.79128 12.3965ZM3.13366 18.6838H12.4489C14.0786 18.6838 14.8935 17.8604 14.8935 16.2564V8.04763C14.8935 6.45221 14.0786 5.62876 12.4489 5.62876H10.1587V6.74384H12.4232C13.2809 6.74384 13.7784 7.20703 13.7784 8.10768V16.1963C13.7784 17.097 13.2809 17.5602 12.4232 17.5602H3.15081C2.2759 17.5602 1.80414 17.097 1.80414 16.1963V8.10768C1.80414 7.20703 2.2759 6.74384 3.15081 6.74384H5.42387V5.62876H3.13366C1.50392 5.62876 0.680475 6.44363 0.680475 8.04763V16.2564C0.680475 17.8689 1.50392 18.6838 3.13366 18.6838Z"/>
        </svg>
        <b>${navigator.share ? 'Share link' : 'Copy post link' }</b>
      </span>
      <span class="drop">
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.30896 9.35184C7.6349 9.35184 7.90939 9.22318 8.15814 8.97443L14.2739 2.7128C14.4712 2.50694 14.5742 2.26677 14.5742 1.97513C14.5742 1.3747 14.1024 0.902937 13.5191 0.902937C13.2361 0.902937 12.9616 1.02302 12.7471 1.23746L7.31753 6.82146L1.87078 1.23746C1.66492 1.0316 1.39901 0.902937 1.0988 0.902937C0.515522 0.902937 0.0437563 1.3747 0.0437563 1.97513C0.0437563 2.25819 0.155265 2.50694 0.352549 2.7128L6.46835 8.97443C6.72568 9.23175 6.99159 9.35184 7.30896 9.35184Z"/>
        </svg>
      </span>
      <span class="flash">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5951 13.8631C5.89531 13.8631 6.11833 13.7345 6.2813 13.4857L14.0526 1.30556C14.1812 1.09969 14.2327 0.953876 14.2327 0.79948C14.2327 0.422067 13.9754 0.16474 13.598 0.16474C13.3235 0.16474 13.1691 0.259093 13.0061 0.51642L5.56936 12.2848L1.76092 7.43852C1.58937 7.20692 1.4264 7.10399 1.16907 7.10399C0.783082 7.10399 0.5086 7.3699 0.5086 7.75589C0.5086 7.91886 0.577221 8.09041 0.705884 8.26196L4.89174 13.4771C5.0976 13.743 5.30346 13.8631 5.5951 13.8631Z"/>
        </svg>
        <b>Post link copied!</b>
      </span>
    </a>`)

    let selfClose = fn => e => { menu.removeClass('open'); fn(e) }

    let highlight, regenerate
    if (api.profile?.admin) {
      if (api.profile?.username === 'kicks') {
        regenerate = u(`<li><a class="icon" href="javascript:;">
            ${icons['post-tweet']}
            <span>Regenerate images</span>
          </a></li>`).
        on('click', selfClose(async e => {
          let {post, frame} = await getPost()
          let gen = await tasks.generateImages(post, frame)
          let form = new FormData()
          form.append("preview", gen.preview, "mv_preview.png")
          form.append("full", gen.full, "mv_full.png")
          await api.put("posts/img/" + post.id, form, api.token)
        }))
      }

      highlight = u(`<li><a class="icon" href="javascript:;">
          ${icons['post-tweet']}
          <span>Highlight in Multiverse</span>
        </a></li>`).
      on('click', selfClose(e => {
        let post = JSON.parse(document.getElementById("post-meta").textContent)
        api.highlightPost(post.id)
      }))
    }

    let tweet = u(`<li><a class="icon" href="javascript:;">
        ${icons['post-tweet']}
        <span>Tweet</span>
      </a></li>`).
    on('click', selfClose(e =>
      window.open("http://twitter.com/share?url=" + window.location.href)))

    let canShareFiles = navigator.canShare && navigator.canShare({files: [new File([], 'test.png')]})
    let imageExport = u(`<li><a class="icon" href="javascript:;">
        ${icons['post-image']}
        <span>${canShareFiles ? 'Share image' : 'Export as image'}</span>
      </a></li>`).
    on('click', selfClose(e => {
      let urlp = window.location.pathname.split('/')
      let url = `/user/${urlp[1]}/posts/${urlp[2]}/mv_full.png`
      if (canShareFiles) {
        fetch(url).then(resp => {
          resp.blob().then(blob => {
            navigator.share({files: [
              new File([blob], `${urlp[2]}.png`, {type: 'image/png'})]})
          })
        })
      } else {
        utils.download({url, filename: `${urlp[2]}.png`})
      }
    }))

    let instagramExport
    // = u(`<li><a class="icon" href="javascript:;">
    //     <span>Export for Instagram</span>
    //   </a></li>`).
    // on('click', selfClose(e => e))

    let svgExport = u(`<li><a class="icon" href="javascript:;">
        ${icons['post-image']}
        <span>Export as SVG</span>
      </a></li>`).
    on('click', selfClose(async e => {
      let {post, frame} = await getPost()
      let url = await tasks.generateSvg(post, frame)
      let urlp = window.location.pathname.split('/')
      utils.download({url, filename: `${urlp[2]}.svg`})
    }))

    let htmlExport
    // = u(`<li><a class="icon" href="javascript:;">
    //     <span>Export as html</span>
    //   </a></li>`).
    // on('click', selfClose(e => e))

    //
    // Action events
    //
    share.on('click', e => {
			u(e.currentTarget).addClass('pressed')
      utils.copyLink()
    })
    u('.drop', share.first()).on('click', e => {
      e.stopPropagation()
      menu.toggleClass('open')
    })

    let menu = u('<ul class="menu mvt_shadow">')
    for (let action of [regenerate, highlight, tweet, imageExport, instagramExport, svgExport, htmlExport]) {
      if (action) {
        menu.append(action)
      }
    }
    banner.append(share)
    banner.append(menu)
    u("#app").prepend(banner)

    //
    // Insert the subscription pane if the user isn't logged in.
    //
    if (!api.profile) {
      let subForm = u(`<div>
        <form>
          <input class="mvt_light" type="email" id="email" name="user:email" placeholder="heyo@multiverse.plus" />
          <button class="mvt_light">Sign up</button>
        </form>
        <div class="error"></div>
      </div>`)
      subForm.on('submit', e => {
        e.preventDefault()
        api.form(e.target, 'POST', 'signup').
          then(() => window.location = "/profile/signin").
          catch(e => u('.subpane .error').html(`<p>${e.details}</p>`))
      })
      let subContent = u(`<div class="content mvt_frame">
        <h3>Welcome to Multiverse!~*</h3>
        <p>Multiverse is a new and expressive blogging network. We are currently
           in private beta, sign up here to make some comic-like blogposts with us!</p>
      </div>`)
      subContent.append(subForm)
      let subFrame = u(`<div class="frame">
        <div class="fill mvt_fill"></div>
      </div>`)
      subFrame.append(subContent)
      let subPane = u(`<div class="subpane">
        <div class="kaylen"><img src="${images['call-kaylen']}"></div>
      </div>`)
      subPane.prepend(subFrame)
      u("#app").append("<br>").append(subPane)
    }

    let h1 = u("h1.p-name")
    api.title(h1.text())
  }
}
